import React, { useEffect, useState } from "react";

import { getDocs, collection } from "firebase/firestore";
import { db } from "../Firebase/firebase";
import { Link, useNavigate } from "react-router-dom";

import { CircularProgress, Rating } from "@mui/material";

// ----------------------------------------          Load all Posts from firebase {posts} document ----------------------------------------

function Posts(props) {
  const navigate = useNavigate();
  //Get the email name before the @ symbol
  const [posts, setPosts] = useState([]);
  const [slicedEmail, setSlicedEmail] = useState([]);
  const [postImage, setPostImage] = useState(null);
  const [defaultPostImage, setDefaultPostImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDocs(collection(db, "posts")).then((docSnap) => {
      docSnap.forEach((doc) => {
        setPosts((newposts) => [...newposts, doc]);
        setSlicedEmail((postsEmail) => [...postsEmail, doc.data().email]);
      });
      setLoading(false);
    });
  }, []);

  function manipulateDescription(postDesc) {
    let a = postDesc.data().desc;

    if (postDesc.data().desc.length > 100) {
      a = postDesc.data().desc.substr(0, 100);
      a += "...";
    }

    return a;
  }

  useEffect(() => {
    setDefaultPostImage(
      <img
        src={postImage}
        alt="Food default"
        id="defaultimg"
        width="385"
        height="300"
      />
    );
  }, [postImage]);
  //Displays all posts in database.
  return (
    <div className="h-auto w-full overflow-hidden flex flex-row-reverse flex-wrap">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {posts.map((post, index) =>
          loading ? (
            <div
              className="flex flex-col w-100 p-4 m-6 bg-white rounded-lg shadow-md"
              key={`all_posts_recipes${post.id}`}
            >
              <Link to={`/post/${post.data().category}/${post.id}`}>
                {post.data().imageURL === null ? (
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/gingerrmarket.appspot.com/o/defaultImages%2Fphoto-1546069901-ba9599a7e63c.jpg?alt=media&token=c55c094f-91f0-4d7a-85d4-8ae2d125cce2"
                    }
                    alt="header"
                    className="w-72 h-72 rounded-full object-cover"
                  />
                ) : (
                  <img
                    src={post.data().imageUrl}
                    alt="header"
                    className="w-full h-[300px] object-cover object-center rounded-t-lg"
                  />
                )}
              </Link>

              <div className="my-2">
                <h2 className="max-w-lg p-12 mx-auto bg-white shadow-lg rounded-lg overflow-hidden">{post.data().title}</h2>
              </div>

              <div className="flex flex-row justify-between m-2 md:space-y-2">
                <div>
                  <p className="text-gray-400">
                    {post.data().calorieTotal} cal
                  </p>
                </div>
                <div className="self-center">
                  <Rating
                    name="read-only"
                    value={post.data().rating}
                    readOnly
                    precision={0.5}
                  />
                </div>
              </div>

              <div className="pt-4 pb-2">
                {post.data().tags.map((postElement) => (
                  <span
                    className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-gray-300 cursor-pointer"
                    key={Math.random().toString(36).substr(2, 9)}
                  >
                    <p>#{postElement}</p>
                  </span>
                ))}
              </div>

              <div className="w-4/5 break-words my-2 py-2">
                {post.data().desc === undefined
                  ? ""
                  : manipulateDescription(post)}
              </div>

              <Link
                to={`/explore/${post.data().category}`}
                className="text-center align-middle w-1/3 bg-green-200 mx-2 rounded-md text-black no-underline"
              >
                {post.data().category}
              </Link>

              <Link
                to={`/profile/${post.data().username_of_user}`}
                className="text-salmon underline mt-4"
              >
                By:{" "}
                {post.data().username_of_user === undefined
                  ? ""
                  : post.data().username_of_user}
              </Link>
            </div>
          ) : (
            // ... your Skeleton loading code
            <CircularProgress />
          )
        )}
      </div>
    </div>
  );
}

export default Posts;
