import { Alert, Button, Card, Typography } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import './Logout.css'; // import custom styles
import { useEffect, useState } from 'react';

const { Title } = Typography;

function LogoutPage(props) {
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('info');
    const [alertMessage, setAlertMessage] = useState('');
    // Check the value of the "status" query parameter in the URL
    const searchParams = new URLSearchParams(window.location.search);
    const status = searchParams.get("status");
    useEffect(() => {
      // Set the alert message and type based on the value of the "status" parameter
      if (status === 'success') {
        setShowAlert(true);
        setAlertType('success');
        setAlertMessage('You have successfully been logged out!');
      } else if (status === 'failed') {
        setShowAlert(true);
        setAlertType('error');
        setAlertMessage('Logout failed. Please try again later or contact support if the problem persists.');
      }
    }, [status]);
  
    return (
      <div className="logout-page-container">
        <Card className="logout-card" bordered={false}>
          <Title level={2} className="logout-title">
          Gentle-Gourmet
          </Title>
          {showAlert && (
            <Alert
              message={alertMessage}
              type={alertType}
              showIcon
              className="logout-alert"
            />
          )}
          <Button
            type="primary"
            shape="round"
            icon={<LogoutOutlined />}
            size="large"
            className="logout-button"
            href="/login"
          >
            Log In Again
          </Button>
        </Card>
      </div>
    );
  }
  

export default LogoutPage;