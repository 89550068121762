import React, { useEffect, useState } from "react";
import PostsTailWind from "./PostsTailWind";
import { Pagination } from "antd";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import { db } from "../components/Firebase/firebase";
import { CircularProgress } from "@mui/material";

export default function ShowPostsTailWind({ currentlySignedInUser, currentUser, userInformation, userParams }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3; // Number of posts per page
  const [lastDoc, setLastDoc] = useState(null); // Last document of the batch, used for cursor based pagination
  const [firstDocs, setFirstDocs] = useState({ 1: null });

  // Get the total amount of posts the user have for the Pagination component to show the correct amount of pages
  const [totalPostsUser, setTotalPostsUser] = useState(null);

  useEffect(() => {
   
    setLoading(true);
    fetchPosts(1).then(() => {
      const q = query(
        collection(db, "posts"),
        where("id_of_user", "==", currentUser.uid),
        orderBy("createdAt", "desc")
      );
      getDocs(q).then((snapshot) => {
        setTotalPostsUser(snapshot.size);
        setLoading(false);
      });
    });
  }, [currentUser]);

  // Update this function:
  const fetchPosts = (page) => {
    return new Promise((resolve, reject) => {
      setCurrentPage(page);
      setLoading(true);

      let q = query(
        collection(db, "posts"),
        where("id_of_user", "==", currentUser.uid),
        orderBy("createdAt", "desc"),
        limit(postsPerPage)
      );

      if (page > 1) {
        if (firstDocs[page]) {
          // If we've been on this page before, we can start at the first document
          q = query(
            collection(db, "posts"),
            where("id_of_user", "==", currentUser.uid),
            orderBy("createdAt", "desc"),
            startAt(firstDocs[page]),
            limit(postsPerPage)
          );
        } else {
          // If we haven't been on this page before, we'll have to start after the last document of the previous page
          q = query(
            collection(db, "posts"),
            where("id_of_user", "==", currentUser.uid),
            orderBy("createdAt", "desc"),
            startAfter(lastDoc),
            limit(postsPerPage)
          );
        }
      }

      getDocs(q)
        .then((docSnap) => {
          let postsArray = [];
          docSnap.forEach((doc) => {
            postsArray.push(doc);
          });
          setPosts(postsArray);

          if (!firstDocs[page]) {
            // If we haven't been on this page before, we save the first document for later
            setFirstDocs((prevFirstDocs) => ({
              ...prevFirstDocs,
              [page]: docSnap.docs[0],
            }));
          }

          setLastDoc(docSnap.docs[docSnap.docs.length - 1]);
          setLoading(false);
          resolve(); // Resolve the promise when the posts are fetched
        })
        .catch(reject); // Reject the promise if an error occurs
    });
  };

  return (
    <div className="flex flex-col items-center justify-center">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {loading ? (
        <div className="w-full flex justify-center items-center">
          <CircularProgress /> {/* Assuming you're using Material-UI */}
        </div>
      ) : posts.length > 0 ? (
        posts.map((post, index) => (
          <PostsTailWind
            key={post.id}
            title={post?.data().title}
            image={post?.data().imageUrl}
            description={post?.data().desc}
            rating={post?.data().rating}
            tags={post?.data().tags}
            category={post?.data().category}
            currentUserId={currentUser}
            postUid={post.id}
            post={post}
            userInformation={userInformation}
            userParams={userParams}
            currentlySignedInUser={currentlySignedInUser}
          />
        ))
      ) : (
        <div className="w-full flex justify-center items-center text-lg text-gray-500">
          No posts found.
        </div>
      )}
     
    </div>
     {totalPostsUser === null ? (
      <div className="w-full flex justify-center items-center">
      <CircularProgress />
      </div>
    ) : (
      <div className="w-full flex justify-center items-center mt-4">
        <Pagination
          current={currentPage}
          onChange={fetchPosts}
          pageSize={postsPerPage}
          total={totalPostsUser}
        />
      </div>
    )}
    </div>
  );
}
