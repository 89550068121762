export const GerdCategoryData = [
  {
    value: "Gastritis-Friendly",
    label: "Gastritis-Friendly",
  },
  {
    value: "IBS-Friendly",
    label: "IBS-Friendly",
  },
  {
    value: "GERD-Friendly",
    label: "GERD-Friendly",
  },
  {
    value: "Barretts-Esophagus-Friendly",
    label: "Barretts-Esophagus-Friendly",
  },
];
