import { useAuth } from "../contexts/AuthContext";
import SiteFooter from "../Footer/SiteFooter";
import ContactForm from "../Media/ContactForm";
import Navbar from "../Navbar/Navbar";
import UserSignedIn from "../Navbar/UserSignedIn";

const ContactPage = () => {
  const { currentUser } = useAuth();
  return (
    <>
    <div className="ContactPageContainer">
      {!currentUser ? <Navbar /> : <UserSignedIn />}
      <ContactForm />
      
    </div>

    </>
  );
};

export default ContactPage;
