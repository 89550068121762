import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "@firebase/auth";
import React, { useContext, useState, useEffect, useCallback } from "react";
import { auth, db } from "../Firebase/firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));
  const [currentUser, setCurrentUser] = useState({});
  const [currentUserInformation, setCurrentUserInformation] = useState({});
  const [authError, setCurrentError] = useState();
  const [hasMounted, setHasMounted] = useState(false);
  const [authState, setAuthState] = useState({
    status: "pending",
    error: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const navigate = useNavigate();
  const clear = () => {
    setCurrentError({});
    setCurrentUser({});
    setIsLoading(false);
  };
  //Catch errors and log onto errorLogs collection
  async function logErrorToDatabase(error, additionalInfo) {
    try {
      const timestamp = serverTimestamp();
      addDoc(collection(db, "errorLogs"), {
        error: error.toString(),
        additionalInfo,
        timestamp,
      });
    } catch (loggingError) {
      console.log("Error while logging error to database", loggingError);
    }
  }

  function clearAuthCookies() {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }
  function clearLocalStorage() {
    localStorage.clear();
  }
  const checkUsernameAvailablity = async (username) => {
    const q = query(collection(db, "users"), where("username", "==", username));
    try {
      const docSnap = await getDocs(q);
      if (docSnap.size > 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      setCurrentError(error);

      return false;
    }
  };

  async function resetPassword(email) {
    try {
      await sendPasswordResetEmail(auth, email);
      return Promise.resolve();
    } catch (error) {
      setCurrentError(error.message);
      return Promise.reject();
    }
  }
  async function sendConfirmationEmail(user) {
    try {
      await sendEmailVerification(user);
      return Promise.resolve();
    } catch (error) {
      setCurrentError(error.message);
      return Promise.reject();
    }
  }
  //const getUser = () => sleep(1000).then(() => ({username: "NULL"}))
  async function signup(
    email,
    password,
    first_name,
    last_name,
    username,
    newsletter
  ) {
    try {
      if (await checkUsernameAvailablity(username)) {
        first_name = first_name === undefined ? "" : first_name;
        last_name = last_name === undefined ? "" : last_name;

        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        await sendConfirmationEmail(userCredential.user);

        await setDoc(doc(db, "users", userCredential.user.uid), {
          firstname: first_name,
          lastname: last_name,
          uid: userCredential.user.uid,
          gastroTag: "No Gastro Tag",
          email: email,
          username: username,
          newsletter: newsletter,
          privateProfile: false,
          monetization: false,
          allowComments: true,
          dailyAmountOfPostsCount: 0,
          dailyAmountOfTimesCanChangeSettings: 0,
          passwordChangeAttempts: 0,
          dailyAmountOfTimesBugReport: 0,
        }).then((newUser) => {
          setCurrentUser(newUser);
          Promise.resolve();
        });
      } else {
        throw new Error("Username is not available");
      }
    } catch (error) {
      let errorMessage = "Something went wrong. Please try again " + error;

      if (error.code === "auth/email-already-in-use") {
        errorMessage = "This email is already in use";
      } else if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email";
      } else if (error.code === "auth/operation-not-allowed") {
        errorMessage = "Email/password accounts are not enabled";
      } else if (error.code === "auth/weak-password") {
        errorMessage = "Please use a stronger password";
      }

      setCurrentError(errorMessage);

      setTimeout(() => {
        setCurrentError(null);
      }, 5000);

      throw new Error(errorMessage);
    }
  }

  async function login(email, password) {
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        if (!userCredential.user.emailVerified) {
          navigate("/email-verification", {
            replace: true,
          });
          return Promise.reject(
            "Please verify your email before logging in. Check your spam folder if you can't find the email."
          );
        } else {
          getDoc(doc(db, "users", userCredential.user.uid)).then(
            (usersSnapshot) => {
              if (usersSnapshot.exists()) {
                if (usersSnapshot.data()?.dateMarkedForDeletion) {
                  updateDoc(doc(db, "users", userCredential.user.uid), {
                    markedForDeletion: false,
                    dateMarkedForDeletion: null,
                    privateProfile: false,
                    newsletter: true,
                  }).then(() => {
                    setCurrentUserInformation(usersSnapshot.data());
                    setCurrentUser(userCredential.user);
                    navigate(from, { replace: true });
                    return Promise.resolve();
                  });
                } else {
                  setCurrentUserInformation(usersSnapshot.data());
                  setCurrentUser(userCredential.user);
                  navigate(from, { replace: true });
                  return Promise.resolve();
                }
              } else {
                setCurrentError("User does not exist");
              }
            }
          );
        }
      })
      .catch((error) => {
        if (error.code === "auth/too-many-requests") {
          return Promise.reject(
            "Account is temporarily disabled due to many failed login attempts. You can restore it by resetting your password."
          );
        }
        return Promise.reject("Incorrect email or password");
      });
    setTimeout(() => {
      setCurrentError(null);
    }, 3000);
  }

  const logout = useCallback(() => {
    // Navigate to the homepage and force a reload
    try {
      navigate("/logout?status=success", {
        replace: true,
        state: { forceRefresh: true },
      });
      auth
        .signOut()
        .then(() => {
          clear();
          // Remove any persistent authentication information
          clearAuthCookies();
          clearLocalStorage();

          setCurrentUser(null);
          setCurrentUserInformation(null);
        })
        .catch((error) => {
          // Handle the error in a more informative way
          setCurrentError(
            `Something went wrong. Please try again. Error: ${error.message}`
          );

          setTimeout(() => {
            setCurrentError(null);
          }, 3000);
        });
    } catch (error) {
      //Handle the error in a more informative way
      navigate("/logout?status=failed", {
        replace: true,
        state: { forceRefresh: true },
      });
      setCurrentError(
        `Something went wrong. Please try again. Error: ${error.message}`
      );
    }
  }, [navigate, setCurrentError]);

  useEffect(() => {
    setIsLoading(true);
    setHasMounted(true);
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      setAuthState({
        status: "success",
        error: null,
      });

      setIsLoading(false);
    });

    return unsubscribe;
  }, []);

  // New useEffect hook to fetch user information
  useEffect(() => {
    if (!hasMounted) return;
    if (currentUser) {
      try {
        const unsubscribe = onSnapshot(
          doc(db, "users", currentUser.uid),
          (doc) => {
            if (doc.exists()) {
              setCurrentUserInformation(doc.data());
              if (currentUser !== null) {
                if (
                  !currentUser?.emailVerified &&
                  location.pathname !== "/auth/action"
                ) {
                  navigate("/email-verification", { replace: true });
                }
              }
            } else {
              setCurrentUserInformation(null);
            }
          }
        );
        return unsubscribe;
      } catch (error) {
        // Use debugger to see error
        //logErrorToDatabase(error, " Error getting document in useEffect ");
      }
    } else {
      setCurrentUserInformation(null);
    }
  }, [currentUser, hasMounted]);

  const value = {
    signup,
    currentUser,
    logout,
    authError,
    login,
    resetPassword,
    currentUserInformation,
    logErrorToDatabase,
  };
  return (
    <AuthContext.Provider value={value}>
      {authState.status === "pending" ? (
        <CircularProgress
          color="inherit"
          sx={{ marginLeft: "50%", marginTop: "30%" }}
        />
      ) : authState.status === "error" ? (
        <div>
          Something went wrong
          <div>
            <pre>{authState.error}</pre>
          </div>
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}
