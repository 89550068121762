import { Twitter } from "@material-ui/icons";
import React from "react";

const ShareOnTwitter = ({ url, text }) => {
  const shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    url
  )}&text=${encodeURIComponent(text)}`;
  function shareFunction(shareUrl) {
    window.open(
      shareUrl,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
    );
  }
  return (
    <div
      className="single_post_recipe_utilities_twitterIcon"
      onClick={() => shareFunction(shareUrl)}
    >
      <Twitter/>
    </div>
  );
};

export default ShareOnTwitter;
