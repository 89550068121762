import { applyActionCode } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../Firebase/firebase";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

export default function EmailVerfifedConfirmationPage({ mode, oobCode }) {
  const [message, setMessage] = useState("Verifying your email...");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    if (mode === "verifyEmail") {
      applyActionCode(auth, oobCode)
        .then(() => {
          setMessage("Your email has been successfully verified!");
          setLoading(false);
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/expired-action-code":
              setMessage(
                "The verification link has expired. Please request a new one."
              );
              break;
            case "auth/invalid-action-code":
              setMessage(
                "The verification link is invalid. Please request a new one."
              );
              break;
            case "auth/user-disabled":
              setMessage(
                "Your account has been disabled. Please contact support."
              );
              break;
            case "auth/user-not-found":
              setMessage(
                "The user associated with this link was not found. Please sign up for a new account."
              );
              break;
            default:
              setMessage(
                "An error occurred while verifying your email. Please try again later."
              );
          }
          setLoading(false);
        });
    } else {
      message.error("Invalid mode.");
      setLoading(false);
    }
  }, []);

  return (
    <div>
      {loading ? (
        <CircularProgress
        color="inherit"
        sx={{ marginLeft: "50%", marginTop: "30%" }}
      />
      ) : (
        <Result
          title={message}
          status={
            message === "Your email has been successfully verified!"
              ? "success"
              : "error"
          }
          subTitle={
            message === "Your email has been successfully verified!"
              ? "You can now access all features on the site."
              : "Please try again or contact support."
          }
          extra={[<Button onClick={() => navigate("/")}>Home</Button>]}
        />
      )}
    </div>
  );
}
