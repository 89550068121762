import { Skeleton } from "antd";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import { db } from "../Firebase/firebase";
import RecipeSearch from "../SearchBar/RecipeSearch";
import "./Dialog_card.css";
import AdComponent from "../../widgets/AdComponent";

function Dialog_card(props) {
  const { defaultPage, recipeSideMenuObject, show } = props;
  const [posts, setPosts] = useState([]);
  const [loadingExplorePosts, setLoadingExplorePosts] = useState(false);

  function manipulateDescription(postDesc) {
    let a = postDesc.data().desc;

    if (postDesc.data().desc.length > 75) {
      a = postDesc.data().desc.substr(0, 75);
      a += "...";
    }
    return a;
  }

  useEffect(() => {
    setLoadingExplorePosts(true);
    if (show) {
      const q = query(
        collection(db, "posts"),
        where("privatePosts", "==", false),
        limit(3),
        orderBy("rating", "desc"),
        orderBy("createdAt", "desc")
      );
      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          setPosts((post) => [...post, doc]);
        });
        setLoadingExplorePosts(false);
      });
    } else {
      setLoadingExplorePosts(false);
      setPosts([]);
    }
  }, [show]);
  const navigate = useNavigate();
  if (defaultPage === "Explore") {
    return (
      <>
        {loadingExplorePosts === true ? (
          <Skeleton active paragraph={{ rows: 3 }} />
        ) : (
          posts.map((post) => (
            <div className="dialog_card d_card">
              <h2>{post.data().title}</h2>
              <div className="dialog_card_description_limited">
                <p>
                  {post.data().desc === undefined
                    ? ""
                    : manipulateDescription(post)}
                </p>
              </div>
              {/* <div className="dialog_card_category_label">
                {post.data().category}
              </div> */}
              <div
                className="dialog_readmore"
                onClick={() => {
                  navigate(`/post/${post.data().category}/${post.id}`);
                }}
              >
                <span>Read More</span> <ArrowRight />
              </div>
            </div>
          ))
        )}
        {/* <div className="dialog_card d_card">
          <a href="#" className="dialog_text">
            Title
          </a>
          <p>
            Fish, burnt food, broccoli, bacon — all leave lingering odors in
            your kitchen.Here's how to neutralize these unpleasant aromas.
          </p>
          <div className="dialog_readmore">
            <span>Read More</span> <ArrowRight />
          </div>
        </div>
        <div className="dialog_card d_card2">
          <a href="#" className="dialog_text">
            Title
          </a>
          <p>
            Fish, burnt food, broccoli, bacon — all leave lingering odors in
            your kitchen.Here's how to neutralize these unpleasant aromas.
          </p>
          <div className="dialog_readmore">
            <span>Read More</span> <ArrowRight />
          </div>
        </div>
        <div className="dialog_card d_card3">
          <a href="#" className="dialog_text">
            Title
          </a>
          <p>
            Fish, burnt food, broccoli, bacon — all leave lingering odors in
            your kitchen.Here's how to neutralize these unpleasant aromas.
          </p>
          <div className="dialog_readmore">
            <span>Read More</span> <ArrowRight />
          </div>
        </div> */}
      </>
    );
  } else if (defaultPage === "Find A Recipe") {
    return <RecipeSearch />;
  } else {
    return (
      <>
        <div className="dialog_page_wrapper">
          <div className="dialog_page_title">
            <h3>{defaultPage}</h3>
            <a
              href={`/explore/${defaultPage}/all`}
              className="dialog_page_title_link"
            >
              {"  SEE ALL " + defaultPage}
            </a>
          </div>
          <div className="dialog_card_submenu_body">
            <ul className="dialog_card_submenu_list">
              {recipeSideMenuObject.linkItems.map((innerMenuOptions) => (
                <li
                  className="dialog_cards_list_items"
                  onClick={() => {
                    navigate(`/explore/${defaultPage}/${innerMenuOptions}`, {
                      replace: true,
                    });
                    window.location.reload();
                  }}
                >
                  {innerMenuOptions}
                </li>
              ))}
            </ul>
            <div className="dialog_card_sidebar">
              <h1>Sponsor</h1>
              <div className="dialog_card_sidebar_ad"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dialog_card;
