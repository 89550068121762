import { Rating } from '@mui/material'
import { collection, doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../Firebase/firebase'
import './Review.css'

export default function Review({reviewData}) {
  const [posterGastroTag, setPosterGatroTag] = useState()
  useEffect(() => {
   getDoc(doc(db, 'users', reviewData.name)).then((queryValue) => {
    setPosterGatroTag(queryValue.data()?.gastroTag)
   })
  
  }, [reviewData])
  return (
    <div className="review_post_profile_page">
        <div className="review_post_top_view">
            <h3>{reviewData.email}</h3>
            {posterGastroTag === null ? "Loading" : posterGastroTag}
        </div>
        <div className="review_post_date_reviews">
            <Rating name="read-only" value={reviewData.rating} readOnly/>
        </div>

        <div className="review_post_desc">
            <p>{reviewData.message}</p>
        </div>
    </div>
  )
}
