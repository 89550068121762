import { Rating, Skeleton } from "@mui/material";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../components/Firebase/firebase";

export default function PostsShowOnHome({ amountOfPosts, category }) {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [slicedEmail, setSlicedEmail] = useState([]);
  const [defaultPostImage, setDefaultPostImage] = useState(null);
  const [postImage, setPostImage] = useState(null);
  const navigate = useNavigate();
  function manipulateDescription(postDesc) {
    let a = postDesc.data().desc;

    if (postDesc.data().desc.length > 100) {
      a = postDesc.data().desc.substr(0, 100);
      a += "...";
    }

    return a;
  }
  const postsRef = collection(db, "posts");
  //Create a query to get a specfic amount of posts based on amountOfPosts
  const q = query(
    postsRef,
    where("category", "==", category),
    where("privatePosts", "==", false),
    limit(amountOfPosts),
    orderBy("rating", "asc")
  );
  useEffect(() => {
    setLoading(false);
    getDocs(q).then((docSnap) => {
      docSnap.forEach((doc) => {
        setPosts((lastPost) => [...lastPost, doc]);
        setSlicedEmail((postsEmail) => [...postsEmail, doc.data().email]);
      });
      setLoading(true);
    });
  }, []);

  useEffect(() => {
    setDefaultPostImage(
      <img
        src={postImage}
        alt="Food default"
        id="defaultimg"
        width="385"
        height="300"
      />
    );
  }, [postImage]);

  return (
    <div className="h-auto w-full overflow-hidden flex flex-row-reverse flex-wrap">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {posts.map((post, index) =>
          loading ? (
            <div className="max-w-lg p-12 mx-auto bg-white shadow-lg rounded-lg overflow-hidden" key={`home_post${post.id}`}>
              <Link to={`/post/${post.data().category}/${post.id}`}>
                {post.data().imageURL === null ? (
                  <img
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/gingerrmarket.appspot.com/o/defaultImages%2Fphoto-1546069901-ba9599a7e63c.jpg?alt=media&token=c55c094f-91f0-4d7a-85d4-8ae2d125cce2"
                    }
                    alt="header"
                    
                    className="w-full h-[300px] object-cover object-center rounded-t-lg"
                  />
                ) : (
                  <img
                    src={post.data().imageUrl}
                    alt="header"
                    className="w-full h-[300px] object-cover object-center rounded-t-lg"
                  />
                )}
              </Link>

              <div className="my-2">
                <h2 className="text-xl font-bold mb-2 text-gray-800">
                  {post.data().title}
                </h2>
              </div>
              <div className="flex flex-row justify-between m-2 md:space-y-2">
                <div>
                  <p className="text-gray-400">
                    {post.data().calorieTotal} cal
                  </p>
                </div>
                <div className="">
                  <Rating
                    name="read-only"
                    value={post.data().rating}
                    readOnly
                    precision={0.5}
                  />
                </div>
              </div>
              <div className=" pt-4 pb-2">
                    {Array.isArray(post?.data().tags) &&
                      post?.data().tags.map((tag, index) => (
                        <span
                          key={index}
                          onClick={() => {navigate(`/explore/${post.data().category}/${tag}`)}}
                          className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-gray-300 cursor-pointer"
                        >
                          #{tag}
                        </span>
                      ))}
                  </div>
              <div className="w-4/5 break-words my-2 py-2">
                {post.data().desc === undefined
                  ? ""
                  : manipulateDescription(post)}
              </div>
              <div className="flex flex-col ">
              <Link
                to={`/explore/${post.data().category}/all`}
                className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-green-400 cursor-pointer md:w-1/3"
              >
                <p>{post.data().category}</p>
              </Link>

              <Link
                to={`/profile/${post.data().username_of_user}`}
                className="recipe_card_post_emailSlicedName"
              >
                By:{" "}
                {post.data().username_of_user === undefined
                  ? ""
                  : post.data().username_of_user}
              </Link>
              </div>
            </div>
          ) : (
            <div>
              <Skeleton variant="rect" width={385} height={300} />
              <Skeleton variant="text" width={100} height={40} />
              <Skeleton variant="circle" width={40} height={40} />
              <Skeleton variant="rect" width={100} height={20} />
              <Skeleton variant="text" width={300} height={20} />
              <Skeleton variant="text" width={100} height={20} />
            </div>
          )
        )}
      </div>
    </div>
  );
}
