import React, { useEffect } from "react";

import jQuery from "jquery";
import { useAuth } from "../contexts/AuthContext";
import Navbar from "../Navbar/Navbar";
import UserSignedIn from "../Navbar/UserSignedIn";
import { useNavigate } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomForm from "../utils/CustomForm";
import diet_image from "../images/dieta-para.jpg"

export default function Newsletter() {
    const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = React.useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData(event.target);
    const url = event.target.action; // URL from the action attribute of the form

    try {
        const response = await fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if (response.ok) {
            // Successful submission
            setSubmissionSuccess(true);
            navigate('/success_page?status=success');
        } else {
            // Handle errors, for example, show a message to the user
            setSubmissionSuccess(false);
            navigate('/current_page?status=failure');
        }
    } catch (error) {
        // Handle network errors
        setSubmissionSuccess(false);
        navigate('/current_page?status=failure');
    } finally {
        setIsSubmitting(false);
    }
};
//   useEffect(() => {
//     // Create script element
//     try {
//       const script = document.createElement("script");
//       script.src =
//         "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
//       script.async = true;

//       // Append the script to the DOM
//       document.body.appendChild(script);

//       // Define the Mailchimp fields and types
//       window.fnames = [];
//       window.ftypes = [];
//       window.fnames[0] = "EMAIL";
//       window.ftypes[0] = "email";
//       window.fnames[1] = "FNAME";
//       window.ftypes[1] = "text";
//       window.fnames[2] = "LNAME";
//       window.ftypes[2] = "text";
//       window.fnames[3] = "ADDRESS";
//       window.ftypes[3] = "address";
//       window.fnames[4] = "PHONE";
//       window.ftypes[4] = "phone";
//       window.fnames[5] = "BIRTHDAY";
//       window.ftypes[5] = "birthday";

//       if (typeof jQuery !== "undefined") {
//         const $mcj = jQuery.noConflict(true);
//       }

//       return () => {
//         document.body.removeChild(script);
//       };
//     } catch (err) {
//       console.log(err);
//     }
//   }, []);
  const mailchimpUrl = "https://app.us12.list-manage.com/subscribe/post?u=d2efa1212ff18f337c2aa30ce&amp;id=195eb4aefa";
  return (
    <>
      {!currentUser ? <Navbar /> : <UserSignedIn />}
      <div className="flex items-center justify-center min-h-screen">
            <div>
            <div className="bg-white-200 p-10">
            <div className="text-center">
                <h2 className="text-3xl font-bold mb-3">Newsletters</h2>
                <p className="text-gray-700">
                    Sign up and we'll send our best Gastritis-friendly recipes, cooking tips, must-have kitchen products and news —
                    straight to your inbox.
                </p>
                <p className="text-gray-700">
                Want to be a part of a growing market of over 100+ people who are interested in Gastritis-friendly recipes? <p onClick={() => navigate("/sign_up")} className="cursor-pointer underline">Sign-Up to our platform</p>
                </p>
            </div>
        </div>
        
   
                <MailchimpSubscribe
                    url={mailchimpUrl}
                    render={({ subscribe, status, message }) => (
                        <CustomForm
                            status={status}
                            formMessage={message}
                            onValidated={formData => subscribe(formData)}
                        />
                    )}
                />
            </div>
        </div>
    </>
  );
}
