import React from "react";
import { Button, message } from "antd";
import { auth, db } from "../Firebase/firebase";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { set } from "react-hook-form";
import { useNavigate } from "react-router-dom";
export default function GoogleLoginInButton() {
  const [loading, setLoading] = React.useState(false);
  // Build Firebase credential with the Google ID token.
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const navigate = useNavigate();
  //Recursively check if the username is available
  const checkUsernameAvailablity = async (username) => {
    const q = query(collection(db, "users"), where("username", "==", username));
    try {
      const docSnap = await getDocs(q);
      if (docSnap.size > 0) {
        // If username exists, add a random character at the end and check again
        const newUsername = username + getRandomCharacter();
        return await checkUsernameAvailablity(newUsername);
      } else {
        return username;
      }
    } catch (error) {
      message.error("Failed to check username availability. Try again");
      return false;
    }
  };

  const getRandomCharacter = () => {
    // Generate a random lowercase character
    return String.fromCharCode(97 + Math.floor(Math.random() * 26));
  };

  const signIn = () => {
    setLoading(true);
    signInWithPopup(auth, provider)
      .then(async (result) => {
        // The signed-in user info.
        const user = result.user;

        // Now you can use the idToken and accessToken as needed...
        // Check if the user already exists in Firestore
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        const uniqueUsername = user.email.split("@")[0];
        const emailUsername = await checkUsernameAvailablity(uniqueUsername);
        if (!userDoc.exists()) {
          await setDoc(doc(db, "users", user.uid), {
            firstname: "",
            lastname: "",
            uid: user.uid,
            gastroTag: "No Gastro Tag",
            email: user.email,
            username: emailUsername,
            newsletter: false,
            privateProfile: false,
            monetization: false,
            allowComments: true,
            dailyAmountOfPostsCount: 0,
            dailyAmountOfTimesCanChangeSettings: 0,
            passwordChangeAttempts: 0,
            dailyAmountOfTimesBugReport: 0,
          });
        }
      })
      .catch((error) => {
        // Handle Errors here.
        setLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(`Error in signInWithPopup: ${errorCode} ${errorMessage}`)
      
      });
  };
  return (
    <Button loading={loading} onClick={() => signIn()}>
      Log in with Google
    </Button>
  );
}
