import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Steps, message } from "antd";
import React, { useState } from "react";

export default function AddRecipeStep2({
  setSteps,
  setRecipeData,
  recipeData,
}) {
  const [ingredients, setIngredients] = useState([
    {
      id: 0,
      name: "",
    },
    {
      id: 1,
      name: "",
    },
    {
      id: 2,
      name: "",
    },
  ]);

  const [ingredientCount, setIngredientCount] = useState(3);

  const onFinish = (values) => {
    if (ingredients.length === 0) {
      message.error("Failed: Please add at least one ingredient");
      return;
    } else {
      // Extracting ingredients from values
      const extractedIngredients = ingredients.map((ingredient) => {
        const id = ingredient.id;
        return {
          label: values[`ingredient-${id}`],
          calories: values[`calories-${id}`],
          checked: false,
        };
      });

      // Merge the extracted ingredients into the recipe data
      setRecipeData((prev) => ({
        ...prev,
        ingredients: extractedIngredients,
      }));

      message.success("Step Added!");
      setSteps(() => 3);
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Failed: " + errorInfo.errorFields[0].errors[0]);
  };

  const addIngredient = () => {
    setIngredients((prev) => [...prev, { id: ingredientCount, name: "" }]);
    setIngredientCount(ingredientCount + 1);
  };

  const handleRemove = (ingredientIndex) => {
    setIngredients(ingredients.filter((_, index) => index !== ingredientIndex));
  };

  const handlePrevious = () => {
    setSteps(() => 1);
  };

  const handleNext = () => {
    setSteps(() => 3);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center px-4 md:px-8 lg:px-12 py-6">
      <div className="flex flex-col items-start w-1/2 mb-10 gap-2">
        <h3 className="text-lg md:text-xl lg:text-2xl font-medium text-black mb-2">
          Add New Recipe
        </h3>
        <p className="text-md md:text-lg lg:text-xl font-medium text-gray-600 mb-4">
          Input recipe details
        </p>
      </div>
      <Steps
        current={1}
        className="w-full md:w-3/4 lg:w-1/2"
        items={[
          {
            title: "Recipe Details",
          },
          {
            title: "Ingredients",
          },
          {
            title: "Instructions",
          },
        ]}
      />
      <div className="flex flex-col items-start w-1/2 mb-10 mt-20 gap-2">
        <h3 className="text-lg md:text-xl lg:text-lg font-medium text-black mb-2">
          Ingredients
        </h3>
        <p className="text-md md:text-lg lg:text-xl font-medium text-gray-600 mb-4">
          Enter one ingredients per line
        </p>
      </div>
      {/* Start of the Form */}
      <Form
        className="w-full md:w-3/4 lg:w-1/2 mt-6"
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="flex flex-col mb-10 gap-2">
          {ingredients.map((ingredient, index) => (
            <Form.Item key={ingredient.id}>
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    name={`ingredient-${ingredient.id}`}
                    label={`Ingredient ${index + 1}`}
                    rules={[
                      { required: true, message: "This field is required" },
                      {
                        validator: (_, value) => {
                          if (value && value.replace(/\s+/g, "").length < 3) {
                            return Promise.reject(
                              new Error(
                                "Please enter at least 3 characters excluding whitespaces."
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                      {
                        whitespace: true,
                        message: "Please enter a valid ingredient",
                      },
                    ]}
                  >
                    <Input
                      placeholder="1/2 ounces of chopped Ginger"
                      suffix={
                        <Button
                          type="text"
                          onClick={() => handleRemove(index)}
                          icon={<CloseCircleOutlined />}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={`calories-${ingredient.id}`}
                    label="Calories"
                    rules={[
                      { required: true },
                      {
                        type: "number",
                        min: 1,
                        max: 9999,
                        message: "Please enter a valid calorie number (1-9999)",
                      },
                    ]}
                    normalize={(value) => {
                      return Number(value);
                    }}
                  >
                    <Input type="number" maxLength={4} placeholder="e.g. 150" />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          ))}
        </div>
        <div className="mb-10">
          <Button
            onClick={() => {
              addIngredient();
            }}
          >
            <PlusOutlined /> Add Ingredients
          </Button>
        </div>
        <div className="flex flex-row items-center justify-around w-1/2 mb-10 mt-20 gap-10">
          <div className="pr-20">
            <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="prev">
              <Button
                onClick={() => {
                  handlePrevious();
                }}
                className=" mr-4"
                type="primary"
                style={{ backgroundColor: "black", color: "white", width: 250 }}
                htmlType="button"
              >
                Prev
              </Button>
            </Form.Item>
          </div>
          <div className="pl-20">
            <Form.Item wrapperCol={{ offet: 8, span: 16 }} name="submit">
              <Button
                className="ml-4"
                type="primary"
                style={{ backgroundColor: "black", color: "white", width: 250 }}
                htmlType="submit"
              >
                Next
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
