import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useAuth } from "../contexts/AuthContext";
import Navbar from "../Navbar/Navbar";
import UserSignedIn from "../Navbar/UserSignedIn";
import { message } from "antd";
import { sendEmailVerification } from "firebase/auth";

const EmailVerificationPage = () => {
  async function sendConfirmationEmail(user) {
    try {
      await sendEmailVerification(user);
      return Promise.resolve();
    } catch (error) {
      message.error("Failed to send verification email");
      return Promise.reject();
    }
  }
  const { currentUser } = useAuth();
  async function handleResendEmail() {
   
    // Implement your resend email verification logic here
    if (currentUser) {
      if (currentUser.emailVerified === true) {
        message.success("Email already verified");
      } else {
        try {
          await sendEmailVerification(currentUser);
          message.success("Verification email sent");
        } catch {
          message.error("Failed to send verification email");
        }
      }
    }
  }

  return (
    <>
      {!currentUser ? <Navbar /> : <UserSignedIn />}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: (theme) => theme.palette.background.default,
          p: 3,
        }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
              bgcolor: (theme) => theme.palette.background.paper,
              boxShadow: (theme) => theme.shadows[4],
              p: 4,
            }}
          >
            <CheckCircleOutlineIcon
              sx={{
                fontSize: 96,
                color: (theme) => theme.palette.success.main,
                mb: 2,
              }}
            />
            <Typography variant="h4" align="center" mb={1}>
              Email Verification
            </Typography>
            <Typography variant="body1" align="center" mb={2}>
              We've sent a verification email to your inbox. Please click the
              link in the email to verify your account.
            </Typography>
            <Button
              sx={{
                mt: 2,
              }}
              variant="contained"
              color="primary"
              onClick={() => handleResendEmail()}
            >
              Resend Verification Email
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default EmailVerificationPage;
