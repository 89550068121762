import { useState } from "react";
import { Form, Input, Button, message } from "antd";

import "./ContactForm.css";
import {
  addDoc,
  collection,
  doc,
  increment,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../Firebase/firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { getFunctions, httpsCallable } from "firebase/functions";

const { TextArea } = Input;
const LIMIT_PER_DAY = 3;
const ContactForm = () => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const { currentUser, currentUserInformation } = useAuth();
  // Google Recaptcha
  const [recaptcha, setRecaptcha] = useState(null);
  const getFunctionsInstance = getFunctions();

  const onRecaptchaChange = (value) => {
    setRecaptcha(value);
  };

  const handleSubmit = async (values) => {
    setSubmitting(true);

    const verifyRecaptcha = httpsCallable(
      getFunctionsInstance,
      "verifyRecaptcha"
    );

    try {
      if (currentUser) {
        if (
          currentUserInformation?.dailyAmountOfTimesBugReport < LIMIT_PER_DAY
        ) {
          await verifyRecaptcha({ recaptchaToken: recaptcha });
          const question = {
            question: values.question,
            email: currentUser ? currentUser.email : "",
            timestamp: new Date(),
          };
          await addDoc(collection(db, "questions"), question);
          await updateDoc(doc(db, "users", currentUser.uid), {
            dailyAmountOfTimesBugReport: increment(1),
          });
          form.resetFields();
          message.success("Your question has been submitted!");
          window.location.reload();
        } else {
          message.error(
            "You have reached your daily amount of questions you can submit today."
          );
        }
      } else {
        message.error("You must be logged in to submit a question.");
      }
    } catch (error) {
      form.resetFields();
      if (error.message.includes("reCAPTCHA")) {
        message.error("You must complete the reCAPTCHA to submit a question.");
      } else {
        message.error(
          "There was an error submitting your question. Please try again."
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitFailed = (errorInfo) => {
    message.error(
      "There was an error submitting your question. Please try again."
    );
  };
  return (
    <div className="contact-form">
      <h1>Please provide valuable feedback to the GentleGourmet team.</h1>
      <p>We welcome bug reports, error notifications, and general inquiries.</p>
      <Form
        form={form}
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
      >
        <Form.Item
          name="question"
          rules={[{ required: true, message: "Please enter your question." }]}
        >
          <TextArea placeholder="Enter your question here..." rows={5} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Submit
          </Button>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA}
            onChange={onRecaptchaChange}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ContactForm;
