import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../Firebase/firebase";
import "./Profile.css";
import "../Media/Posts.css";
import EditProfile from "./EditProfile";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Menu,
  Rating,
} from "@mui/material";
import { Button, Dropdown, message } from "antd";
import { Settings } from "@material-ui/icons";
import {
  CircularProgress,
  DialogActions,
  DialogContentText,
  DialogTitle,
  ListItemText,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import UserSignedIn from "../Navbar/UserSignedIn";
import Bookmark from "../../widgets/Bookmarks";
import TwitterTabs from "../Media/TabPanel";
import {
  LikeFilled,
  PlusCircleFilled,
  ProjectFilled,
  SettingFilled,
} from "@ant-design/icons";

import ProfilePostsSideBar from "../Profile/ProfilePostsSideBar";
import ProfileBookmarksSideBar from "../Profile/ProfileBookmarksSideBar";
export default function Profile({ match }) {
  const { currentUser, logout, currentUserInformation } = useAuth();
  const { username } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [focusedPostToBeDeleted, setFocusedPostToBeDeleted] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonDeletePostEventState, setButtonDeletePostEventState] =
    useState(false);

  const navigate = useNavigate();
  //Dialog box that asks the user if they wish to confirm the deletion of the post.

  const [dialogConfirmDeltePost, setDialogConfirmDeltePost] =
    React.useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const open = Boolean(anchorEl);
  const deletePostDialogOpen = Boolean(dialogConfirmDeltePost);

  const handleClick = (event, post) => {
    setFocusedPostToBeDeleted(post);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeletePostDialogClose = () => {
    setDialogConfirmDeltePost(false);
    setFocusedPostToBeDeleted(null);
  };

  // const openDeletePostDialogOption = (post) => {
  //   setDialogConfirmDeltePost(true);
  // };

  const deletePostWithFirebase = () => {
    //Delete the post from firebase
    setButtonDeletePostEventState(true);
    deleteDoc(doc(db, "posts", focusedPostToBeDeleted.id))
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        window.location.reload();
        setButtonDeletePostEventState(false);
      });
  };
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/profile/accounts/user_account"
        >
          Edit Profile
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="/profile/accounts/deactivate"
        >
          Deactivate/Delete Account
        </a>
      ),
    },
  ];

  const searchParams = new URLSearchParams(window.location.search);
  const status = searchParams.get("status");
  //Get all posts that belongs to the user and display on profile page.
  //const postsRef = collection(db, "posts", uid)

  const [userInformation, setUserInformation] = useState(null);
  const [getTabValue, getSetTabValue] = useState(0);
  const [userPosts, setUserPosts] = useState([]);

  const [updatedGastroTagSuccuessValue, setUpdatedGastroTagSuccuessValue] =
    useState(null);

  const [handleSnackbarValue, setHandleSnackbarValue] = useState(true);

  const [mainScreenPage, setMainScreenPage] = useState(2);

  const handleSnackBarClickEvent = () => {
    setHandleSnackbarValue(false);
  };
  const fetchUserPosts = async (username, lastDocSnapshot = null) => {
    const usersQuery = query(
      collection(db, "users"),
      where("username", "==", username)
    );
    const userSnap = await getDocs(usersQuery);
    const userId = userSnap.docs[0].id;
    let postsQuery = query(
      collection(db, "posts"),
      where("id_of_user", "==", userId),
      orderBy("createdAt", "desc"),
      limit(3)
    );
    if (lastDocSnapshot) {
      postsQuery = query(
        collection(db, "posts"),
        where("id_of_user", "==", userId),
        orderBy("createdAt", "desc"),
        startAfter(lastDocSnapshot),
        limit(3)
      );
    }

    const postsSnapshot = await getDocs(postsQuery);

    if (postsSnapshot.empty) {
      setHasMore(false);
      return;
    }

    const lastVisible = postsSnapshot.docs[postsSnapshot.docs.length - 1];
    setLastDoc(lastVisible);

    const newPosts = postsSnapshot.docs.map((doc) => doc);
    setUserPosts((prev) => [...prev, ...newPosts]);
  };

  useEffect(() => {

    const q = query(collection(db, "users"), where("username", "==", username));
    getDocs(q).then((docSnap) => {
      docSnap.forEach((doc) => {
        setUserInformation((userinfo) => (userinfo = doc));
      });
    }, []);

    //Fetch all posts that belongs to the user
    fetchUserPosts(username)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        message.error("Error fetching posts. Please try again later. " + err);
        setLoading(false);
      });
  }, [username]);

  // const loadMorePosts = () => {
  //   setLoading(true);
  //   fetchUserPosts(username, lastDoc)
  //     .then(() => setLoading(false))
  //     .catch(() => {
  //       // Handle error
  //       setLoading(false);
  //     });
  // };
  // function showGastroTag() {
  //   if (userInformation !== null) {
  //     if (userInformation.gastroTag !== "") {
  //       return <span>{userInformation.data().gastroTag}</span>;
  //     } else {
  //       return <span>Empty</span>;
  //     }
  //   } else {
  //     return <span>Empty</span>;
  //   }
  // }

  function showBookMarkSettingButton(post) {
    if (userInformation !== null && currentUser !== null) {
      return (
        <div>
          <IconButton
            id="demo-positioned-button"
            aria-controls={open ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => handleClick(event, post)}
          >
            <Settings />
          </IconButton>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem>
              <Bookmark
                postID={
                  focusedPostToBeDeleted === null
                    ? post.id
                    : focusedPostToBeDeleted.id
                }
                userID={currentUser.uid}
              />
            </MenuItem>
          </Menu>
        </div>
      );
    } else {
      return null;
    }
  }

  // function showPostSettingButton(post) {
  //   if (userInformation !== null && currentUser !== null) {
  //     if (currentUser.uid === userInformation.data().uid) {
  //       return (
  //         <div>
  //           <IconButton
  //             id="demo-positioned-button"
  //             aria-controls={open ? "demo-positioned-menu" : undefined}
  //             aria-haspopup="true"
  //             aria-expanded={open ? "true" : undefined}
  //             onClick={(event) => handleClick(event, post)}
  //           >
  //             <Settings />
  //           </IconButton>
  //           <Menu
  //             id="demo-positioned-menu"
  //             aria-labelledby="demo-positioned-button"
  //             anchorEl={anchorEl}
  //             open={open}
  //             onClose={handleClose}
  //             anchorOrigin={{
  //               vertical: "top",
  //               horizontal: "left",
  //             }}
  //             transformOrigin={{
  //               vertical: "top",
  //               horizontal: "left",
  //             }}
  //           >
  //             <MenuItem>
  //               <ListItemText onClick={() => openDeletePostDialogOption(post)}>
  //                 Delete Post
  //               </ListItemText>
  //             </MenuItem>
  //           </Menu>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div>
  //           <IconButton
  //             id="demo-positioned-button"
  //             aria-controls={open ? "demo-positioned-menu" : undefined}
  //             aria-haspopup="true"
  //             aria-expanded={open ? "true" : undefined}
  //             onClick={(event) => handleClick(event, post)}
  //           >
  //             <Settings />
  //           </IconButton>
  //           <Menu
  //             id="demo-positioned-menu"
  //             aria-labelledby="demo-positioned-button"
  //             anchorEl={anchorEl}
  //             open={open}
  //             onClose={handleClose}
  //             anchorOrigin={{
  //               vertical: "top",
  //               horizontal: "left",
  //             }}
  //             transformOrigin={{
  //               vertical: "top",
  //               horizontal: "left",
  //             }}
  //           >
  //             <MenuItem>
  //               <Bookmark
  //                 postID={
  //                   focusedPostToBeDeleted === null
  //                     ? post.id
  //                     : focusedPostToBeDeleted.id
  //                 }
  //                 userID={currentUser.uid}
  //               />
  //             </MenuItem>
  //           </Menu>
  //         </div>
  //       );
  //     }
  //   } else {
  //     return (
  //       <CircularProgress
  //         color="inherit"
  //         sx={{ marginLeft: "50%", marginTop: "50%" }}
  //       />
  //     );
  //   }
  // }

  // function showSettingsButton() {
  //   if (userInformation !== null && currentUser !== null) {
  //     if (currentUser.uid === userInformation.data().uid) {
  //       return (
  //         <div>
  //           <Dropdown.Button
  //             menu={{
  //               items,
  //             }}
  //             placement="bottomLeft"
  //           >
  //             Settings
  //           </Dropdown.Button>
  //           {/* <Button variant="contained" onClick={() => navigateToEditProfile()}>
  //             Edit Profile
  //           </Button>
  //           <Settings /> */}
  //         </div>
  //       );
  //     } else {
  //       return null;
  //     }
  //   }
  // }

  return !currentUser ? (
    <CircularProgress
      color="inherit"
      sx={{ marginLeft: "50%", marginTop: "50%" }}
    />
  ) : (
    <>
      {status === "deleted"
        ? message.success("Post deleted successfully.")
        : null}
      {/* Header bar if a user is signed in.*/}
      <UserSignedIn />
      {/* Tailwindcss code testing */}

      <Dialog
        open={deletePostDialogOpen}
        onClose={handleDeletePostDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this post?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeletePostDialogClose}
            disabled={buttonDeletePostEventState}
          >
            Close
          </Button>
          <Button
            onClick={() => deletePostWithFirebase()}
            autoFocus
            disabled={buttonDeletePostEventState}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {updatedGastroTagSuccuessValue}

      {/* Profile Section */}
      <div className="profile_page_section">
        {status === "added" ? (
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={handleSnackbarValue}
            autoHideDuration={6000}
            onClose={handleSnackBarClickEvent}
          >
            <Alert
              severity="success"
              onClose={handleSnackBarClickEvent}
              sx={{ width: "100%" }}
            >
              Success! Your post has been made!
            </Alert>
          </Snackbar>
        ) : null}
        {status === "error" ? (
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={handleSnackbarValue}
            autoHideDuration={6000}
            onClose={handleSnackBarClickEvent}
          >
            <Alert
              severity="error"
              onClose={handleSnackBarClickEvent}
              sx={{ width: "100%" }}
            >
              Error! Sorry! While adding your post, something went wrong. Check
              back later. I'm sorry for the trouble.
            </Alert>
          </Snackbar>
        ) : null}
        {/* </Collapse> */}
        {/* Containter for profile section */}
        <div className="flex h-screen ">
          <div className="w-1/3 bg-white p-4 S">
            {/* Sidebar */}
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {userInformation === null
                ? null
                : userInformation.data().firstname}{" "}
              {userInformation === null
                ? null
                : userInformation.data().lastname}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Personal details and application.
            </p>

            {/*If the user's username that's currently logged in doesn't match the username in the url don't show bookmarks on setting.  */}
            {username !== currentUserInformation?.username ? null : (
              <div
                className="mt-6 p-1 hover:bg-gray-400 bg-gray flex flex-row cursor-pointer"
                onClick={() => setMainScreenPage(1)}
              >
                <LikeFilled className="mr-4" />
                <p className="text-gray-500 text-sm">Bookmarks</p>
              </div>
            )}

            <div
              className="mt-6 p-1 hover:bg-gray-400 bg-gray flex flex-row cursor-pointer "
              onClick={() => setMainScreenPage(2)}
            >
              <PlusCircleFilled className="mr-4" />
              <p className="text-gray-500 text-sm">Posts</p>
            </div>
            <div
              className="mt-6 p-1 hover:bg-gray-400 br-gray flex flex-row cursor-pointer"
              onClick={() => {
                navigate("/profile/accounts/user_accounts");
              }}
            >
              <SettingFilled className="mr-4" />
              <p className="text-gray-500 text-sm">Settings</p>
            </div>
            <div className="mt-20 p-1 cursor-pointer" onClick={() => logout()}>
              <p className="text-red-500 text-md ">Log Out</p>
            </div>
          </div>
          {/* Main Screen */}
          <div className="w-2/3 p-4 shadow-lg">
            {/* Profile Section */}
            <h3 classNmae="text-lg leading-6 font-medium text-gray-900">
              {mainScreenPage === 1 ? (
                <ProfileBookmarksSideBar
                  userID={
                    userInformation === null
                      ? "232"
                      : userInformation.data().uid
                  }
                  currentUser={currentUser}
                  userInformation={userInformation}
                  userParams={username}
                  currentlySignedInUser={currentUserInformation}
                />
              ) : mainScreenPage === 2 ? (
                <ProfilePostsSideBar
                  posts={userPosts}
              /* userInformation.data() is the currently signed in user */
                  currentUser={userInformation?.data()}
                  userInformation={userInformation}
                  userParams={username}
                  currentlySignedInUser={currentUserInformation}
                />
              ) : (
                "Settings"
              )}
            </h3>
          </div>
        </div>

        {/* <div className="profile_page_picture"></div>
          <div className="profile_page_username_and_tag_wrapper">
            <div className="profile_tab_buttons"></div>
            <div className="profile_page_username">
              <h2>
                {userInformation === null
                  ? null
                  : userInformation.data().firstname}
              </h2>
              <h2>
                {userInformation === null
                  ? null
                  : userInformation.data().lastname}
              </h2>
            </div>
            <div className="profile_page_gastro_tag">
              <Box
                component="span"
                sx={{
                  backgroundColor: "#6BAB90",
                  borderRadius: "8px",
                  padding: "3px",
                  margin: "10px",
                }}
              >
                {showGastroTag()}
              </Box>
            </div>
          </div> */}
      </div>

      {/* <Grid item xs={4}> */}
      {/* {currentUser.uid === username ? (
        <EditProfile setAlert={setUpdatedGastroTagSuccuessValue} />
      ) : null} */}
      {/* <div className="profile_page_edit_profile_button">
              {showSettingsButton()}
            </div> */}
      {/* </Grid> */}
    </>
  );
}
