import React from 'react';
import {Navigate, useLocation, Route, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function AuthRouteIfLoggedOut  ({component: Component, ...rest})   {
    
    const {currentUser} =  useAuth()

    return (currentUser? <Outlet/> : <Navigate to ="/login?redirected=login"/>)
}

export default AuthRouteIfLoggedOut;