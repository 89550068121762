import React from 'react';
import { Result, Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import './PageNotFound.css';
import { useNavigate } from 'react-router-dom';

const PageNotFound = ({ history }) => {
    const navigate = useNavigate()
  return (
    <div className="not-found-page">
      <Result
        status="404"
        title="404"
        subTitle="Ohhh my stomach! The page you're looking for doesn't exist."
        extra={
          <Button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"type="primary" onClick={() => navigate("/")}>
            <HomeOutlined /> Go Home
          </Button>
        }
      />
    </div>
  );
};

export default PageNotFound;