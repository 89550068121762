import React from 'react'

export default function ManipulateDesciption({desc}) {
    function manipulateDescription(postDesc) {
        let a = postDesc;
    
        if (postDesc?.length > 200) {
          a = postDesc?.substr(0, 200);
          a += "...";
        }
    
        return a;
      }
  return (
    <div>{manipulateDescription(desc)}</div>
  )
}
