import React, { useEffect } from "react";
import "./Navbar.css";
import Burgermenu from "./Burgermenu";
import { useState } from "react";
import { auth } from "../Firebase/firebase";
import { useAuth } from "../contexts/AuthContext";
import UserSignedIn from "./UserSignedIn";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { Button } from "antd";
import logo from "../images/gentlegourmeticon.png";
//Main Navbar that controls the apperance and functions of all other conditional navbars..

function Navbar(props) {
  const { currentUser, logout } = useAuth();
  const [navbar, setNavBar] = useState("");
  const [isFetched, setIsFetched] = useState(false);
  const navigate = useNavigate();
  let logOutStyle = {
    cursor: "pointer",
    color: "lightblue",
  };

  async function signOut() {
    logout();
  }

  useEffect(() => {
    if (currentUser !== undefined) {
      setIsFetched(true);
    }
  }, [currentUser]);

  function handleClick(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/sign_up");
    }
  }

  function handleLoginClick(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/login");
    }
  }
  //Checks to see if a user is signed in on load.
  if (currentUser) {
    return <UserSignedIn user={currentUser} />;
  } else {
    //Main navbar that shows when a user isn't signed in.
    return (
      <nav className="NavbarItems">
  <div className="menu-logo">
  <div className="menu-icon">
      <Burgermenu />
    </div>
    <div className="logo-desktop">
      <h2 className="logo" onClick={() => navigate("/")}>Gentle Gourmet</h2>
    </div>
    <div className="logo-mobile">
      <h2 className="logo" onClick={() => navigate("/")}>Home</h2>
    </div>
   
  </div>
  <ul className="nav-menu">
    <li className="nav-item">
      <Button className="nav-links signup_button" onClick={(e) => handleClick(e)}>Get Started</Button>
    </li>
    <li className="nav-item">
      <Button className="nav-links login_button" onClick={(e) => handleLoginClick(e)}>Login</Button>
    </li>
  </ul>
</nav>
    );
  }
}

export default Navbar;
