// import React, { useState, useEffect, useMemo } from "react";
// import { Button, message, Spin } from "antd";
// import { BookOutlined } from "@ant-design/icons";
// import "firebase/firestore";
// import { db } from "../components/Firebase/firebase";
// import {
//   addDoc,
//   collection,
//   deleteDoc,
//   getDocs,
//   query,
//   where,
// } from "firebase/firestore";

// const Bookmark = ({ userID, postID }) => {
//   const [bookmarked, setBookmarked] = useState(false);
//   const bookmarkRef = collection(db, "bookmarks");
//   const [loading, setLoading] = useState(true);
//   // Query the bookmarks collection for userID and postID

//   useEffect(() => {
//     const checkBookmarkStatus = async () => {
//       try {
//         const bookMarkQuery = query(
//             bookmarkRef,
//             where("UserID", "==", userID),
//             where("PostID", "==", postID)
//           );

//         const querySnapshot = await getDocs(bookMarkQuery);
//         setBookmarked(!querySnapshot.empty);
//       } catch (error) {
//         console.error("Error while fetching bookmark status:", error);
//         message.error("An error occurred while fetching bookmark status");
//       } finally {
//         setLoading(false);
//       }
//     };
//     checkBookmarkStatus();
//   }, []);

//   const handleBookmark = () => {
//     console.log("Bookmark button clicked");
//     const bookMarkQuery = query(
//         bookmarkRef,
//         where("UserID", "==", userID),
//         where("PostID", "==", postID)
//       );

//     try {
//       if (!bookmarked) {
//         // Add the bookmark
//         addDoc(collection(db, "bookmarks"), {
//           UserID: userID,
//           PostID: postID,
//         }).then(() => {
//           message.success("Post added to bookmarks");
//           setBookmarked(true);
//         });
//       } else {
//         // Delete the bookmark
//         getDocs(bookMarkQuery).then((querySnapshot) => {
//           if (!querySnapshot.empty) {
//             deleteDoc(querySnapshot.docs[0].ref);
//             message.success("Post removed from bookmarks");
//             setBookmarked(false);
//           } else {
//             console.log("No matching documents.");
//           }
//         });
//       }
//     } catch (error) {
//       message.error("An error occurred while bookmarking the post");
//       console.error("Error while bookmarking the post:", error);
//     }
//   };

//   return (
//     <Button
//       type={bookmarked ? "primary" : "default"}
//       icon={loading ? <Spin size="small" /> : <BookOutlined />}
//       onClick={() => handleBookmark()}
//       disabled={loading}
//     >
//       {bookmarked ? "Remove Bookmark" : "Add to Bookmarks"}
//     </Button>
//   );
// };

// export default Bookmark;

import React, { useState, useEffect } from "react";
import { Button, message, Spin } from "antd";
import { BookOutlined } from "@ant-design/icons";
import "firebase/firestore";
import { db } from "../components/Firebase/firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useAuth } from "../components/contexts/AuthContext";


const Bookmark = ({ userID, postID }) => {
  const {currentUser} = useAuth()
  const [bookmarked, setBookmarked] = useState(false);
  const bookmarksRef = collection(db, "users", currentUser.uid, "bookmarks");
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const checkBookmarkStatus = async () => {
      try {
        const bookMarkQuery = query(
          bookmarksRef,
          where("PostID", "==", postID)
        );
        const querySnapshot = await getDocs(bookMarkQuery);
        setBookmarked(!querySnapshot.empty);
      } catch (error) {
        message.error("An error occurred while fetching bookmark status");
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    checkBookmarkStatus();
  }, [userID, postID, bookmarksRef]);

  const handleBookmark = async () => {
    const bookMarkQuery = query(bookmarksRef, where("PostID", "==", postID));

    try {
      if (!bookmarked) {
        // Add the bookmark
        await addDoc(bookmarksRef, {
          PostID: postID,
        });
        message.success("Post added to bookmarks");
        setBookmarked(true);
      } else {
        // Delete the bookmark
        const querySnapshot = await getDocs(bookMarkQuery);
        if (!querySnapshot.empty) {
          await deleteDoc(querySnapshot.docs[0].ref);
          message.success("Post removed from bookmarks");
          setBookmarked(false);
        } else {
          message.error("An error occurred while bookmarking the post");
        }
      }
    } catch (error) {
      message.error("An error occurred while bookmarking the post");
      message.error(error, "Bookmark + handleBookmark");
    }
  };

  return (
    <Button
      className={
        bookmarked
          ? "bg-red-500 text-white hover:bg-red-700"
          : "bg-blue-500 text-white hover:bg-blue-700"
      }
      icon={loading ? <Spin size="small" /> : <BookOutlined />}
      onClick={() => handleBookmark()}
      disabled={loading}
    >
      {bookmarked ? "Remove Bookmark" : "Add to Bookmarks"}
    </Button>
  );
};

export default Bookmark;
