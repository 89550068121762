import { CircularProgress, Rating, Skeleton, Stack } from "@mui/material";
import { Button } from "antd";
import {
  collection,
  getDocs,
  limit,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { CategoryTagsData } from "../DataVariables/CategoryTagsData";
import { db, storageRef } from "../Firebase/firebase";
import Navbar from "../Navbar/Navbar";
import UserSignedIn from "../Navbar/UserSignedIn";
import "./Posts.css";
export default function RecipeCategoryAndType() {
  const { currentUser } = useAuth();
  const [numOfPostsToLoad, setNumOfPostsToLoad] = useState(3);
  const [lastVisiblePost, setLastVisiblePost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [slicedEmail, setSlicedEmail] = useState([]);
  //Get the email name before the @ symbol
  const [postImage, setPostImage] = useState(null);
  const [defaultPostImage, setDefaultPostImage] = useState(null);
  let { explore, recipe_category, recipe_type } = useParams();
  const searchRef = collection(db, "posts");
  useEffect(() => {
    async function fetchPosts() {
      let queryRef = buildQuery(recipe_category, recipe_type);

      const docSnap = await getDocs(queryRef);
      const lastVisible = docSnap.docs[docSnap.docs.length - 1];
      setLastVisiblePost(lastVisible);

      docSnap.forEach((doc) => {
        setPosts((newposts) => [...newposts, doc]);
        setSlicedEmail((postsEmail) => [...postsEmail, doc.data().email]);
      });
    }

    fetchPosts();
  }, [recipe_category, recipe_type]);
  // useEffect(() => {
  //   if (recipe_category !== "International") {
  //     if (recipe_type === "all") {
  //       try {
  //         const q = query(
  //           searchRef,
  //           where(`category`, "==", `${recipe_category}`),
  //           where("privatePosts", "==", false)
  //         );
  //         getDocs(q).then((docSnap) => {
  //           docSnap.forEach((doc) => {
  //             setPosts((newposts) => [...newposts, doc]);
  //             setSlicedEmail((postsEmail) => [...postsEmail, doc.data().email]);
  //           });
  //         });
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     } else {
  //       try {
  //         if (CategoryTagsData.includes(recipe_type)) {
  //           console.log(`${recipe_type} is a tag`);
  //           console.log(`${recipe_category} is a category`);
  //           const internationalCusineQuery = query(
  //             searchRef,
  //             where("category", "==", `${recipe_category}`),
  //             where("tags", "array-contains", `${recipe_type}`),
  //             where("international", "==", false),
  //             where("privatePosts", "==", false)
  //           );

  //           getDocs(internationalCusineQuery).then((docSnap) => {
  //             docSnap.forEach((doc) => {
  //               setPosts((newposts) => [...newposts, doc]);
  //               setSlicedEmail((postsEmail) => [
  //                 ...postsEmail,
  //                 doc.data().email,
  //               ]);
  //             });
  //           });
  //         } else {
  //           const q = query(
  //             searchRef,
  //             where(`category`, "==", `${recipe_category}`),
  //             where("tags", "array-contains", `${recipe_type}`),
  //             where("privatePosts", "==", false)
  //           );
  //           getDocs(q).then((docSnap) => {
  //             docSnap.forEach((doc) => {
  //               setPosts((newposts) => [...newposts, doc]);
  //               setSlicedEmail((postsEmail) => [
  //                 ...postsEmail,
  //                 doc.data().email,
  //               ]);
  //             });
  //           });
  //         }
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     }
  //   } else if (recipe_category === "International") {
  //     if (recipe_type === "all") {
  //       try {
  //         const q = query(
  //           searchRef,
  //           where(`international`, "==", true),
  //           where("privatePosts", "==", false)
  //         );
  //         getDocs(q).then((docSnap) => {
  //           docSnap.forEach((doc) => {
  //             setPosts((newposts) => [...newposts, doc]);
  //             setSlicedEmail((postsEmail) => [...postsEmail, doc.data().email]);
  //           });
  //         });
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     } else {
  //       const q = query(
  //         searchRef,
  //         where("tags", "array-contains", `${recipe_type}`),
  //         where("privatePosts", "==", false)
  //       );
  //       getDocs(q).then((docSnap) => {
  //         docSnap.forEach((doc) => {
  //           setPosts((newposts) => [...newposts, doc]);
  //           setSlicedEmail((postsEmail) => [...postsEmail, doc.data().email]);
  //         });
  //       });
  //     }
  //   }
  // }, []);

  function buildQuery(category, type, startAfterDoc = null) {
    let baseQuery = searchRef;

    if (category !== "International") {
      baseQuery = query(
        baseQuery,
        where("category", "==", category),
        where("privatePosts", "==", false)
      );
    } else {
      baseQuery = query(
        baseQuery,
        where("international", "==", true),
        where("privatePosts", "==", false)
      );
    }

    if (type !== "all") {
      baseQuery = query(baseQuery, where("tags", "array-contains", type));
    }

    if (startAfterDoc) {
      baseQuery = query(baseQuery, startAfter(startAfterDoc));
    }

    baseQuery = query(baseQuery, limit(numOfPostsToLoad));

    return baseQuery;
  }

  async function loadMorePosts() {
    setLoading(true);
    if (lastVisiblePost) {
      let queryRef = buildQuery(recipe_category, recipe_type, lastVisiblePost);

      const docSnap = await getDocs(queryRef);
      const lastVisible = docSnap.docs[docSnap.docs.length - 1];
      setLastVisiblePost(lastVisible);

      docSnap.forEach((doc) => {
        setPosts((newposts) => [...newposts, doc]);
        setSlicedEmail((postsEmail) => [...postsEmail, doc.data().email]);
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  function manipulateDescription(postDesc) {
    let a = postDesc.data().desc;
    if (postDesc.data().desc.length >= 100) {
      a = postDesc.data().desc.substr(0, 100);
      a += "...";
    }

    return a;
  }

  useEffect(() => {
    setDefaultPostImage(
      <img
        src={postImage}
        alt="Food default"
        id="defaultimg"
        width="385"
        height="300"
      />
    );
  }, [postImage]);
  //Displays all posts in database.
  return (
    <>
      {!currentUser ? <Navbar /> : <UserSignedIn />}
      <div className="h-auto w-full overflow-hidden flex flex-row-reverse flex-wrap">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {posts !== null ? (
            posts.map((post) =>
              post !== undefined ? (
                <div
                  className="max-w-lg p-12 mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
                  key={post.data().title}
                >
                  <Link to={`/post/${post.data().category}/${post.id}`}>
                    {post.data().imageUrl === null ? (
                      <img
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/gingerrmarket.appspot.com/o/defaultImages%2Fphoto-1546069901-ba9599a7e63c.jpg?alt=media&token=c55c094f-91f0-4d7a-85d4-8ae2d125cce2"
                        }
                        alt="header"
                        className="w-full h-[300px] object-cover object-center rounded-t-lg"
                      />
                    ) : (
                      <img
                        src={post.data().imageUrl}
                        alt="header"
                        className="w-full h-[300px] object-cover object-center rounded-t-lg"
                      />
                    )}
                  </Link>

                  <div className="my-2">
                    <h1 className="text-xl font-bold mb-2 text-gray-800">
                      {post?.data().title}
                    </h1>
                  </div>

                  <div className="flex flex-row justify-between m-2 md:space-y-2">
                    <div>
                      <p className="text-gray-400">
                        {post.data().calorieTotal} cal
                      </p>
                    </div>
                    <div className="">
                      <Rating
                        name="read-only"
                        value={post?.data().rating}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className=" pt-4 pb-2">
                    {Array.isArray(post?.data().tags) &&
                      post?.data().tags.map((tag, index) => (
                        <span
                          key={index}
                          className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-gray-300 cursor-pointer"
                        >
                          #{tag}
                        </span>
                      ))}
                  </div>

                  <div className="w-4/5 break-words my-2 py-2 ">
                    {post.data().desc === undefined
                      ? ""
                      : manipulateDescription(post)}
                  </div>
                  <div className="flex flex-col ">
                    <Link
                      to={`/explore/${post.data().category}`}
                      className="text-center align-middle w-1/3 bg-green-200 mx-2 rounded-md text-black no-underline"
                    >
                      {post.data().category}
                    </Link>

                    <Link
                      to={`/profile/${post.data().username_of_user}`}
                      className="text-salmon underline mt-4"
                    >
                      By:{" "}
                      {post.data().username_of_user === undefined
                        ? "Error getting Username"
                        : post.data().username_of_user}
                    </Link>
                  </div>
                </div>
              ) : (
                // ... your Skeleton loading code
                <CircularProgress />
              )
            )
          ) : (
            // ... your Skeleton loading code
            <CircularProgress />
          )}
        </div>
        <div className="flex justify-center mt-4 w-full">
          <Button onClick={loadMorePosts} loading={loading}>
            Load More...
          </Button>
        </div>
      </div>
    </>
  );
}
