import {
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  DialogContentText,
  DialogTitle,
  Rating,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import "./Post.css";
import { red } from "@mui/material/colors";
import { FormControl } from "@material-ui/core";
import "./RateRecipeDialog.css";
import PropTypes from "prop-types";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import {
  collection,
  doc,
  getDocs,
  Timestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "../Firebase/firebase";
import { useAuth } from "../contexts/AuthContext";
import Reviews from "./Reviews";
import { Button, message } from "antd";
import { getFunctions, httpsCallable } from "firebase/functions";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function RateRecipeDialog({ title, GETPOSTSLUG }) {
  //handle loading of the dialog box
  const [loading, setLoading] = useState(false);

  //Set if the dialog is visable or not.
  const [open, setOpen] = useState(false);

  //Get ratingValue assigned in the dialog box.
  const [ratingValue, setRatingValue] = useState(0);

  //Get review message in textarea
  const [reviewMessage, setReviewMessage] = useState("");

  //Display reviews underneath posts
  const [reviewPostsComments, setReviewPostComments] = useState([]);

  //Get currentUser
  const { currentUser, logErrorToDatabase } = useAuth();

  //Feedback: no rating selected.
  const [ratingFeedBack, setRatingFeedBack] = useState(null);

  //Get if post allows comments
  const [getPostData, setPostData] = useState({
    allowComments: false,
  });
  useEffect(() => {
    getDoc(doc(db, "posts", `${GETPOSTSLUG}`)).then((docSnap) => {
      setPostData(() => {
        return {
          allowComments: docSnap.data().allowComments,
        };
      });
      if (docSnap.data().allowComments === true) {
        getDocs(collection(db, "posts", `${GETPOSTSLUG}`, "reviews"))
          .then((docSnap) => {
            docSnap.forEach((docElement) => {
              setReviewPostComments((review_comments) => [
                ...review_comments,
                docElement.data(),
              ]);
            });
          })
          .catch((what) => {
            message.error(what.message);
          });
      }
    });
  }, [GETPOSTSLUG]);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  }));

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function printRatingValue(e) {
    setRatingValue(e.target.value);
  }

  function addRatingFunction() {
    const getReviewTime = Timestamp.now().toDate().toString();
    setLoading(true);
    const functions = getFunctions();
    const addRating = httpsCallable(functions, "updateRating");
    if (ratingValue !== 0) {
      addRating({
        name: currentUser.uid,
        rating: ratingValue,
        email: currentUser.email,
        message: reviewMessage,
        pid: GETPOSTSLUG,
        date: getReviewTime,
        user: currentUser.uid,
      })
        .then((result) => {
          if (result.data.success) {
            setLoading(false);
            message.success("Thank you for your review!");
            handleClose();
          } else {
            setLoading(false);
            message.error(result.data.message);
          }
        })
        .catch((error) => {
          logErrorToDatabase(error);
          setLoading(false);
          message.error(error.message);
        });

      // try {
      //   addRating({
      //     name: currentUser.uid,
      //     rating: ratingValue,
      //     email: currentUser.email,
      //     message: reviewMessage,
      //     pid: GETPOSTSLUG,
      //     date: getReviewTime,
      //     user: currentUser.uid,
      //   })
      //     .then((result) => {
      //       console.log(result);
      //       setLoading(false);
      //       message.success("Thank you for your review!");
      //       handleClose();
      //     })
      //     .catch((error) => {
      //       logErrorToDatabase(error);
      //       setLoading(false);
      //       message.error(error.message);
      //     });
      // } catch (error) {
      //   logErrorToDatabase(error);
      //   setLoading(false);
      //   message.error(error.message);
      // }

      // getDocs(collection(db, "posts", `${GETPOSTSLUG}/reviews`)).then(
      //   (reviewCollectionSnapShot) => {
      //     if (reviewCollectionSnapShot.size !== 0) {
      //       //Check if user has already rated the post
      //       getDoc(
      //         doc(db, "posts", `${GETPOSTSLUG}/reviews/${currentUser.uid}`)
      //       )
      //         .then((reviewDocument) => {
      //           if (!reviewDocument.exists()) {
      //             setDoc(
      //               doc(
      //                 db,
      //                 `posts`,
      //                 `${GETPOSTSLUG}/reviews/${currentUser.uid}`
      //               ),
      //               {
      //                 name: currentUser.uid,
      //                 rating: ratingValue,
      //                 email: currentUser.email,
      //                 message: reviewMessage,
      //                 pid: GETPOSTSLUG,
      //                 date: getReviewTime,
      //                 user: currentUser.uid,
      //               }
      //             )
      //               .then(() => {
      //                 getDoc(doc(db, "posts", `${GETPOSTSLUG}`))
      //                   .then((reviewDocument) => {
      //                     ///Math.min(((docSnap.data().rating * 5 + parseInt(ratingValue)) / (5 + 1)), 5);
      //                     const new_rating = Math.min(
      //                       (reviewDocument.data().rating * 5 +
      //                         parseInt(ratingValue)) /
      //                         (5 + 1),
      //                       5
      //                     );
      //                     updateDoc(doc(db, "posts", `${GETPOSTSLUG}`), {
      //                       rating: new_rating,
      //                     }).then(() => {
      //                       window.location.reload();
      //                     });
      //                   })
      //                   .catch((what) => {
      //                     logErrorToDatabase(what.message, "RateRecipeDialog");
      //                   });
      //               })
      //               .catch((what) => {
      //                 setLoading(false);
      //               });
      //           } else {
      //             const oldRating = reviewDocument.data().rating;
      //             setDoc(
      //               doc(
      //                 db,
      //                 `posts`,
      //                 `${GETPOSTSLUG}/reviews/${currentUser.uid}`
      //               ),
      //               {
      //                 name: currentUser.uid,
      //                 rating: ratingValue,
      //                 email: currentUser.email,
      //                 message: reviewMessage,
      //                 pid: GETPOSTSLUG,
      //                 date: getReviewTime,
      //                 user: currentUser.uid,
      //               }
      //             )
      //               .then(() => {
      //                 getDoc(doc(db, "posts", `${GETPOSTSLUG}`))
      //                   .then((reviewDocument) => {

      //                     const new_rating = Math.min(
      //                       (reviewDocument.data().rating * 5 +
      //                         parseInt(ratingValue) -
      //                         parseInt(oldRating)) /
      //                         5,
      //                       5
      //                     );

      //                     updateDoc(doc(db, "posts", `${GETPOSTSLUG}`), {
      //                       //get a rating out of five and limited between one and five.
      //                       rating: new_rating,
      //                     }).then(() => {
      //                       window.location.reload();
      //                     });
      //                   })
      //                   .catch((what) => {
      //                     /*  Add some form of error message later. */
      //                     setLoading(false);
      //                     logErrorToDatabase(what.message, "RateRecipeDialog");
      //                   });
      //               })
      //               .catch((what) => {
      //                 message.error(what.message)
      //                 setLoading(false);
      //               });
      //           }
      //         })
      //         .catch((what) => {
      //           setLoading(false);
      //           message.error(what.message)
      //         });
      //     } else {
      //       setDoc(
      //         doc(db, `posts`, `${GETPOSTSLUG}/reviews/${currentUser.uid}`),
      //         {
      //           name: currentUser.uid,
      //           rating: ratingValue,
      //           email: currentUser.email,
      //           message: reviewMessage,
      //           pid: GETPOSTSLUG,
      //           date: getReviewTime,
      //           user: currentUser.uid,
      //         }
      //       ).then(() => {
      //         getDoc(doc(db, "posts", `${GETPOSTSLUG}`))
      //           .then((docSnap) => {

      //             getDocs(collection(db, "posts", `${GETPOSTSLUG}/reviews`))
      //               .then((totalDocumentCounts) => {
      //                 const new_rating = ratingValue;
      //                 updateDoc(doc(db, "posts", `${GETPOSTSLUG}`), {
      //                   rating: new_rating,
      //                 }).then(() => {
      //                   window.location.reload();
      //                 });
      //               })
      //               .catch((what) => {
      //                 setLoading(false);

      //               });
      //           })
      //           .catch((what) => {
      //             /*  Add some form of error message later. */
      //             message.error("Something went wrong.")
      //             setLoading(false);
      //           });
      //       });
      //     }
      //   }
      // );
    } else {
      //Display user feedback.
      setRatingFeedBack(
        <Alert severity="error">Please give the recipe a rating.</Alert>
      );
      setLoading(false);
      setTimeout(() => {
        setRatingFeedBack(null);
      }, 3000);
    }
  }

  return (
    <div className="single_post_containter">
      <div className="single_post_recipe_customer_feedback">
        <h2>Reviews</h2>
        <div className="single_post_recipe_customer_feedback_banner">
          <div className="single_post_recipe_customer_paragraph_block">
            <p>
              Liked this recipe? Leave a review let others know how this recipe
              felt for you and those who share your gastro condition.
            </p>
          </div>
          {currentUser ? (
            <ColorButton variant="contained" onClick={handleClickOpen}>
              Add your Rating & Review
            </ColorButton>
          ) : null}

          {/* 
 ---------------------------------- Dialog Box -------------------------------------------------------------------------
                    */}
          <Dialog open={open} onClose={() => handleClose} fullWidth={true}>
            <DialogTitle>Review this Recipe</DialogTitle>
            {ratingFeedBack}
            <div className="recipe_title_name_dialog_rate_recipe">
              <h1>{title}</h1>
            </div>
            <DialogContent>
              {/* <div>
                {succuessAlert}
            </div> */}
              <h3>Rating</h3>
              <StyledRating
                name="highlight-selected-only"
                defaultValue={2}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                onChange={printRatingValue}
                value={ratingValue}
                highlightSelectedOnly
              />
              <h3>Your Review (optional)</h3>
              <textarea
                rows="5"
                maxLength={180}
                className="post_textarea_dialog_box_raterecipe_input"
                placeholder="Thoughts on this recipe? How did it effect you? Did you make any changes or notes?"
                onChange={(e) => setReviewMessage(e.target.value)}
              ></textarea>

              <FormControl fullWidth></FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} disabled={loading}>
                Close
              </Button>
              <Button
                variant="contained"
                disabled={loading}
                loading={loading}
                autoFocus
                onClick={() => addRatingFunction()}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>

      {getPostData.allowComments === true ? (
        // If allowComments is true

        <Reviews review_profile={reviewPostsComments} />
      ) : null}
    </div>
  );
}
