import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import React from "react";
import "./DropDownMyAccountMenu.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const DropDownMyAccountMenu = ({ profile }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  function naviagateToProfile() {
    if (profile?.username === "" || profile?.username === undefined) {
      navigate("/404");
    } else {
      navigate(`/profile/${profile?.username}`);
      window.location.reload();
    }
  }

  async function signOut() {
    logout();
  }

  function navigateToAddRecipe() {
    navigate("/new_post");
  }

  const options = [
    { name: "Profile", link: () => naviagateToProfile() },
    { name: "Logout", link: () => signOut() },
    { name: "Add a Recipe", link: () => navigateToAddRecipe() },
  ];

  const menu = (
    <Menu>
      {options.map((option, index) => (
        <Menu.Item key={index}>
          <p onClick={option.link}>{option.name}</p>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["hover"]}>
      <Space className="dropdown-btn">
        My Account <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default DropDownMyAccountMenu;
