import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect } from "react";
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 10,
  },
  image: {
    width: 150,
    height: 200,
    resizeMode: "cover",
    marginBottom: 10,
  },
  section: {
    marginBottom: 20,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 8,
  },
  sectionContent: {
    fontSize: 14,
    lineHeight: 1.5,
  },
  listItem: {
    flexDirection: "row",
    marginBottom: 5,
  },
  listItemIndex: {
    width: 20,
  },
  listItemContent: {
    flex: 1,
  },
});

// Create a pdf from a Post.js component
export default function PostDocument({
  title,
  description,
  ingredients,
  instructions,
  image,
  email,
}) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>{title}</Text>
      
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Description</Text>
          <Text style={styles.sectionContent}>{description}</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Ingredients</Text>
          {ingredients?.map((ingredient, index) => (
            <View key={index} style={styles.listItem}>
              <Text style={[styles.sectionContent, styles.listItemIndex]}>
                {index + 1}.
              </Text>
              <Text style={[styles.sectionContent, styles.listItemContent]}>
                {ingredient.label}
              </Text>
              <Text style={[styles.sectionContent, styles.listItemContent]}>
                {ingredient.get_amount}
              </Text>
            </View>
          ))}
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Instructions</Text>
          {instructions?.map((instruction, index) => (
            <View key={index} style={styles.listItem}>
              <Text style={[styles.sectionContent, styles.listItemIndex]}>
                {index + 1}.
              </Text>
              <Text style={[styles.sectionContent, styles.listItemContent]}>
                {instruction.text}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
