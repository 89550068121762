import React from "react";
import ShowPostsTailWind from "../../widgets/ShowPostsTailWind";
import { Button } from "antd";
// User Params is the username parameters in the url
export default function ProfilePostsSideBar({ posts, currentUser, userInformation, userParams, currentlySignedInUser }) {
  return (
    <>
    <div className="flex flex-col bg-white px-4 py-4 rounded-lg shadow-lg">
      <div className="flex justify-between mb-2">
        <div className="flex flex-col">
          <h3 className="text-lg font-medium text-black mb-2">Posts</h3>
          <p className="text-md font-medium text-gray-600 mb-4">
            Your personal recipes are here.
          </p>
        </div>
  
        <div className="">
          <Button>
            <a href="/new_post">Add A Recipe</a>
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <ShowPostsTailWind posts={posts} currentUser={currentUser} currentlySignedInUser={currentlySignedInUser} userInformation={userInformation} userParams={userParams}/>
        </div>
      </div>
    </div>
  </>
  );
}
