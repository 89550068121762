import { ArrowBack } from "@mui/icons-material";
import { Button, Select, message } from "antd";
import { doc, increment, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../Firebase/firebase";

const LIMIT_PER_DAY = 5;

export default function EditGastroSettingPage({
  userAuthProvider,
  currentUser,
  informationChangedSuccess,
  informationChangedError,
  currentUserInformation,
}) {
  const navigate = useNavigate();
  const [getGerdValue, setGerdValue] = useState(userAuthProvider?.gastroTag);
  const [loading, setLoading] = useState(false);
  const [gerdTagList, setGerdTagList] = useState([
    //TODO Update value labels to make sense lmaooo. Maybe for grouping.
    {
      value: "Gastritis",
      label: "Gastritis",
    },
    {
      value: "IBS",
      label: "IBS",
    },
    {
      value: "GERD",
      label: "GERD",
    },
    {
      value: "Barretts-Esophagus",
      label: "Barretts-Esophagus",
    },
  ]);
  function updateGerdTag() {
    setLoading(true);
    if (currentUserInformation.dailyAmountOfTimesCanChangeSettings <= LIMIT_PER_DAY) {
    updateDoc(doc(db, "users", currentUser.uid), {
      gastroTag: getGerdValue,
      dailyAmountOfTimesCanChangeSettings: increment(1),
    })
      .then(() => {
        navigate(informationChangedSuccess, { replace: true });
        window.location.reload();
        setLoading(false);
      })
      .catch((error) => {
        navigate(informationChangedError, { replace: true });
        window.location.reload();
        setLoading(false);
      });
    } else {
      message.error("You have reached the limit of changing your settings for today. Please try again tomorrow.")
      navigate(informationChangedError, { replace: true });
      window.location.reload();
      setLoading(false);
    }
  }

  return (
    <div className="gerdTag-container">
      <div className="gerdTag-arrow">
        <ArrowBack
          className="gerdTag-arrowIcon"
          onClick={() => {
            navigate("/profile/accounts/user_account");
          }}
        />
      </div>
      <div className="gerdTag-select">
        <small>Select your Gerd-Tag</small>
        <Select
          onChange={(value) => {
            setGerdValue(value);
          }}
          defaultValue={userAuthProvider?.gastroTag}
          style={{ width: "100%" }}
          options={gerdTagList}
        />
      </div>
      <div className="gerdTag-save">
        <Button
          loading={loading}
          disabled={loading}
          variant="contained"
          onClick={() => updateGerdTag()}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
