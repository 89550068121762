import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Burgermenu from "./Burgermenu";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom/dist";
import "./SignUpNavBar.css";

function SignUpNavBar(props) {
  const [isFetched, setIsFetched] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser !== undefined) {
      setIsFetched(true);
    }
  }, [currentUser]);

  function handleLoginClick(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/login");
    }
  }
  return (
    <nav className="NavbarItemsSignUp">
    <div className="menu-section-signup">
     
      <div className="links-signup">
      <h2 className="logo" onClick={() => navigate("/")}>Gentle Gourmet</h2>
      </div>
    </div>
    <div className="right_links-signup">
      <div className="login-option-signup" onClick={(e) => handleLoginClick(e)}>
        <p>Login</p>
      </div>
      <div className="patreon-link-signup">
        <a href="https://www.patreon.com/GentleGourmet/about">Patreon</a>
      </div>
    </div>
  </nav>
  );
}

export default SignUpNavBar;
