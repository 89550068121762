import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import React, { useEffect } from "react";
import "firebase/auth";
import { CircularProgress } from "@mui/material";

const AuthRoute = () => {
  const { currentUser } = useAuth();
 

  return (currentUser ?  <Navigate to="/"/> : <Outlet />);
};

export default AuthRoute;
