import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

import {
  ArrowRight,
  Twitter,
  Instagram,
  ChevronRight,
  Search,
} from "react-feather";

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom/dist";
import { useAuth } from "../contexts/AuthContext";
import Dialog_card from "../DialogMenuPages/Dialog_card";
import { MenuItems } from "../Navbar/MenuItem";
import DialogCardMobileView from "../DialogMenuPages/DialogCardMobileView";
let RecipeObject = {
  title: "Find A Recipe",
  url: "#",
  cName: "nav-links",
};

function MobileMenuDialogDeclare({ show, ClosePopUp }) {
  const { currentUser, logout } = useAuth();
  const [isFetched, setIsFetched] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser !== undefined) {
      setIsFetched(true);
    }
  }, [currentUser]);

  function handleLoginClick(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/login");
    }
  }

  function handleSignUpClick(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/sign_up");
    }
  }

  async function signOut() {
    logout();
  }

  const [menuObject, setMenuObject] = useState(MenuItems[0]);
  if (!show) {
    return <> </>;
  }

  return (
    <>
      <Dialog open={show} onClose={ClosePopUp} maxWidth="xl" fullScreen>
        <DialogTitle>
          <div className="flex justify-between items-center p-4 md:px-8">
            <div className="flex items-center">
              {/* Uncomment the following if you want the image back */}
              {/* <img
                className="h-24 w-24 md:h-40 md:w-40 mr-4"
                src="...your image url here..."
                alt="Gentle-Gourmet Logo"
              /> */}
              <h3 className="text-xl md:text-3xl cursor-pointer" onClick={() => navigate("/")}>Gentle-Gourmet</h3>
            </div>
            <div className="flex items-center">
              <div className="flex space-x-4 mr-4">
                <Twitter className="text-xl" />
                <Instagram className="text-xl" />
              </div>
              {currentUser ? (
                <button
                  className="text-sm md:text-base px-4 py-2 bg-red-500 text-white rounded"
                  onClick={() => signOut()}
                >
                  Logout
                </button>
              ) : (
                <>
                  <button
                    className="text-sm md:text-base px-4 py-2 bg-blue-500 text-white rounded mr-2"
                    onClick={(e) => handleLoginClick(e)}
                  >
                    Login
                  </button>
                  <button
                    className="text-sm md:text-base px-4 py-2 bg-green-500 text-white rounded"
                    onClick={(e) => handleSignUpClick(e)}
                  >
                    Signup
                  </button>
                </>
              )}
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="flex flex-row md:flex-row space-y-4 md:space-y-0 md:space-x-4 p-4">
            {/*Hide this if the DailogCardMobileView is shown*/}
            {menuObject === "" ? (
              <div className="w-full md:w-1/3">
                <ul className="space-y-4">
                  <li>
                    <button
                      className="flex justify-between items-center w-full p-2 bg-gray-200 rounded"
                      onClick={() => setMenuObject(RecipeObject)}
                    >
                      Find a Recipe
                      <Search className="ml-2" />
                    </button>
                  </li>
                  {MenuItems.map((item, index) => (
                    <li key={index}>
                      <button
                        className="flex justify-between items-center w-full p-2 bg-gray-200 rounded"
                        onClick={() => setMenuObject(item)}
                      >
                        {item.title}
                        <ChevronRight />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {menuObject === "" ? null : (
              <DialogCardMobileView
                show={show}
                defaultPage={menuObject.title}
                recipeSideMenuObject={menuObject}
                setMenuObject={setMenuObject}
              />
            )}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={ClosePopUp} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MobileMenuDialogDeclare;
