import {
  Alert,
  CircularProgress,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Snackbar,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserSignedIn from "../Navbar/UserSignedIn";
import "./AccountSettingsPage.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import { useAuth } from "../contexts/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../Firebase/firebase";
import UserAccount from "./UserAccount";
import EditGastroSettingPage from "./EditGastroSettingPage";
import PrivateAccountPrivacySafety from "./PrivateAccountPrivacySafety";
import ChangePasswordForm from "./ChangePasswordForm";
import ShowUserInformation from "./ShowUserInformation";

const style = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
};

export default function AccountSettingsPage() {
  /// CONSTANTS ///
  const PROFILE_ACCOUNT_URL = "/profile/accounts";
  const location = useLocation();
  const hash = location.hash;
  const newHash = hash.substring(1);
  /// USESTATES ///
  const { currentUser, currentUserInformation } = useAuth();
  const [userAuthProvider, setUserAuthProvider] = useState(null);
  let informationChangedSuccess =
    location.state?.from?.pathname ||
    `${PROFILE_ACCOUNT_URL}/edit?status=success`;
  let informationChangedError =
    location.state?.from?.pathname ||
    `${PROFILE_ACCOUNT_URL}/edit?confirm=error`;
  const [changePublicProfileValue, setChangePublicProfileValue] =
    useState(false);
  const [allowComments, setAllowComments] = useState(false);
  const [monetizationValue, setMonetizationValue] = useState(false);
  const [loadingUserInformation, setLoadingUserInformation] = useState(false);
  /// NAVIGATION ///
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const [handleSnackbarValue, setHandleSnackbarValue] = useState(true);
  const status = searchParams.get("status");

  useEffect(() => {
    setLoadingUserInformation(true);
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
   
        getDoc(doc(db, "users", currentUser.uid)).then((doc) => {
          if (doc.exists()) {
            setUserAuthProvider(doc.data());
            setChangePublicProfileValue(doc.data().privateProfile);
            setAllowComments(doc.data().allowComments);
            setMonetizationValue(doc.data().monetization);
            setLoadingUserInformation(false);
          } else {
            // doc.data() will be undefined in this case
       
            navigate(`/login?redirect=${location.pathname}`);
            setLoadingUserInformation(false);
          }
        });
      } else {
        navigate(`/login?redirect=${location.pathname}`);
        setLoadingUserInformation(false);
      }
    });
    return unsubscribe;
  }, [currentUser]);
  const handleSnackBarClickEvent = () => {
    setHandleSnackbarValue(false);
  };

  // Handle the switch for the public profile //
  function handleAccountInformationStatement() {

    if (location.pathname === `${PROFILE_ACCOUNT_URL}/user_account`) {
      return <UserAccount />;
    }
    if (location.pathname === `${PROFILE_ACCOUNT_URL}/gerdtag`) {
      return (
        <EditGastroSettingPage
          userAuthProvider={userAuthProvider}
          currentUser={currentUser}
          informationChangedError={informationChangedError}
          informationChangedSuccess={informationChangedSuccess}
          currentUserInformation={currentUserInformation}
        />
      );
    }
    if (location.pathname === `${PROFILE_ACCOUNT_URL}/privacy_and_safety`) {
      return (
        <PrivateAccountPrivacySafety
          userAuthProvider={userAuthProvider}
          currentUser={currentUser}
          informationChangedError={informationChangedError}
          informationChangedSuccess={informationChangedSuccess}
        />
      );
    }
    if (location.pathname === `${PROFILE_ACCOUNT_URL}/change_password`) {
      return <ChangePasswordForm />;
    }

    if (location.pathname === `${PROFILE_ACCOUNT_URL}/user_information`) {
      return <ShowUserInformation userData={userAuthProvider} style={style} />;
    }
  }

  return !currentUser ? (
    <CircularProgress />
  ) : (
    <>
      {/* Header bar if a user is signed in.*/}
      <UserSignedIn />

      <div className="edit-account-page-container">
        <div className="edit-count-page-divider">
          <div className="edit-account-page-left-side">
            <h3>
              {userAuthProvider === null ? (
                <CircularProgress />
              ) : (
                ` ${userAuthProvider.username}`
              )}
            </h3>
            {status === "success" ? (
              <Snackbar
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                open={handleSnackbarValue}
                autoHideDuration={6000}
                onClose={handleSnackBarClickEvent}
              >
                <Alert
                  severity="success"
                  onClose={handleSnackBarClickEvent}
                  sx={{ width: "100%" }}
                >
                  Changes to profile have been made and saved!
                </Alert>
              </Snackbar>
            ) : null}
            {status === "error" ? (
              <Snackbar
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                open={handleSnackbarValue}
                autoHideDuration={6000}
                onClose={handleSnackBarClickEvent}
              >
                <Alert
                  severity="error"
                  onClose={handleSnackBarClickEvent}
                  sx={{ width: "100%" }}
                >
                  Error Something went wrong when making the changes. Please
                  give it another go later.
                </Alert>
              </Snackbar>
            ) : null}
            {userAuthProvider === null ? (
              <CircularProgress />
            ) : (
              <List sx={style} component="nav" aria-label="mailbox folders">
                <Divider />
                <ListItemButton
                  onClick={() => navigate("/profile/accounts/user_account")}
                >
                  <ListItemText primary="Your Account" />
                </ListItemButton>
                <Divider />
                <ListItemButton
                  divider
                  onClick={() =>
                    navigate("/profile/accounts/privacy_and_safety")
                  }
                >
                  <ListItemText primary="Privacy and Safety" />
                </ListItemButton>
                <Divider light />
              </List>
            )}
          </div>
          {
            // This is the right side of the page. This is where the user can edit their account information.
            userAuthProvider === null ? (
              <CircularProgress />
            ) : (
              <div className="edit-account-page-right-side">
                {handleAccountInformationStatement()}
              </div>
            )
          }
        </div>
      </div>
    </>
  );
}
