import {
  CircularProgress,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Bookmark from "./Bookmarks";
import { Settings } from "react-feather";
import ConfirmDeletePost from "../components/utils/ConfirmDeletePost";
import { Delete } from "@material-ui/icons";
import { Button, message } from "antd";

export default function ShowPostSettingButton({
  postUid,
  currentUserId,
  post,
  userInformation,
  userParams,
  currentlySignedInUser,
  postUserInformation,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dialogConfirmDeltePost, setDialogConfirmDeltePost] = useState(false);
  const [focusedPostToBeDeleted, setFocusedPostToBeDeleted] = useState(null);
  const deletePostDialogOpen = Boolean(dialogConfirmDeltePost);


  const handleClick = (event, post) => {


    let postId;
    // Assuming GraphQL object has an 'id' field. Extract the id from it.
    if (typeof post === "object" && post !== null && "id" in post) {
      postId = post.id;
    } else {
      postId = post;
    }

    setFocusedPostToBeDeleted(postId);
    setAnchorEl(event.currentTarget);
  };

  const openDeletePostDialogOption = () => {
    setDialogConfirmDeltePost(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDeletePostDialogClose = () => {
    setDialogConfirmDeltePost(false);
    setFocusedPostToBeDeleted(null);
  };
  function showPostSettingButton(post) {
    if (userInformation?.uid !== null && currentUserId !== null) {
      if (
        currentlySignedInUser?.username === userParams &&
        postUserInformation === currentlySignedInUser?.username
      ) {
        return (
          <div>
            <IconButton
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event, postUid)}
            >
              <Settings />
            </IconButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem>
                <Button
                  type={"default"}
                  icon={<Delete />}
                  onClick={() => openDeletePostDialogOption(post)}
                  disabled={dialogConfirmDeltePost}
                >
                  Delete post
                </Button>
              </MenuItem>

              <MenuItem>
                <Bookmark
                  postID={
                    focusedPostToBeDeleted === null
                      ? post.id
                      : focusedPostToBeDeleted
                  }
                  userID={currentUserId.uid}
                />
              </MenuItem>
            </Menu>
          </div>
        );
      } else {
        return (
          <div>
            <IconButton
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => handleClick(event, post)}
            >
              <Settings />
            </IconButton>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem>
                <Bookmark
                  postID={
                    focusedPostToBeDeleted === null
                      ? post.id
                      : focusedPostToBeDeleted
                  }
                  userID={currentUserId.uid}
                />
              </MenuItem>
            </Menu>
          </div>
        );
      }
    } else {
      return (
        <CircularProgress
          color="inherit"
          sx={{ marginLeft: "50%", marginTop: "50%" }}
        />
      );
    }
  }

  return (
    <div>
      <ConfirmDeletePost
        focusedPostToBeDeleted={focusedPostToBeDeleted}
        deletePostDialogOpen={deletePostDialogOpen}
        handleDeletePostDialogClose={handleDeletePostDialogClose}
      />
      {showPostSettingButton(post)}
    </div>
  );
}
