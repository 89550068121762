import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Steps, Upload, message } from "antd";
import { Option } from "antd/es/mentions";
import React, { useState } from "react";
import { set } from "react-hook-form";
import { CategoryTagsData } from "../../DataVariables/CategoryTagsData";
import { GerdCategoryData } from "../../DataVariables/GerdCategoryData";

const image = "";
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
// const beforeUpload = (file) => {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//   switch (file.type) {
//     case !isJpgOrPng:
//       message.error("You can only upload JPG/PNG file!");
//       break;
//     case !isLt2M:
//       message.error("Image must smaller than 2MB!");
//       break;
//     default:

//       break;
//   }

//   return isJpgOrPng && isLt2M;
// };

export default function AddRecipeStep1({
  setSteps,
  setRecipeData,
  recipeData,
}) {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [form] = Form.useForm();

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const handleOnChange = (file) => {
   
    const newFile = file.file.originFileObj;
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      //See if image is bigger than 1200pxx1200px
      img.onload = () => {
        if (img.width > 1200 || img.height > 800) {
          file.target.value = null;
          message.error("Image is too big (max 1200x800)");
          setImageUrl(null);
          form.setFieldsValue({ imageUrl: null });
        
        } else {
          setImageUrl(newFile);
          form.setFieldsValue({ imageUrl: newFile });
        }
      };
      img.src = reader.result;
    };
    // If the file meets all conditions, save it to state and prevent auto upload
    reader.readAsDataURL(newFile);

  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onFinish = (values) => {
    message.success("Step added!");
    setRecipeData((prev) => ({ ...prev, ...values }));
    setSteps(() => 2);
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Failed: " + errorInfo.errorFields[0].errors[0]);
  };
  // Add a new Recipe Step One
  return (
    <div className="w-full flex flex-col items-center justify-center px-4 md:px-8 lg:px-12 py-6">
      <div className="flex flex-col items-start w-1/2 mb-10 gap-2">
        <h3 className="text-lg md:text-xl lg:text-2xl font-medium text-black mb-2">
          Add New Recipe
        </h3>
        <p className="text-md md:text-lg lg:text-xl font-medium text-gray-600 mb-4">
          Input recipe details
        </p>
      </div>
      <Steps
        current={0}
        className="w-full md:w-3/4 lg:w-1/2"
        items={[
          {
            title: "Recipe Details",
          },
          {
            title: "Ingredients",
          },
          {
            title: "Instructions",
          },
        ]}
      />
      {/* Start of the Form */}
      <Form
        className="w-full md:w-3/4 lg:w-1/2 mt-6"
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <div className="grid-cols-2 grid gap-4 md:gap-8 lg:gap-12 p-4 md:p-8 lg:p-12 mb-6">
          <Form.Item
            labelCol={{ span: 24 }}
            label="Recipe title"
            name="title"
            
            rules={[
              { required: true },
              {
                type: "string",
                min: 8,
              },
              {
                whitespace: true,
                message: "Please input a valid title",
              },
              {
                validator: (_, value) => {
                  if (value && value.replace(/\s+/g, "").length < 8) {
                    return Promise.reject(
                      new Error(
                        "Please enter at least 8 characters excluding whitespaces."
                      )
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              placeholder="Recipe title"
              defaultValue={recipeData.title === null ? null : recipeData.title}
            />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 24 }}
            label="Upload Image"
            rules={[{ required: true }]}
            name={"imageUrl"}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={true}
              maxCount={1}
              onChange={(e) => handleOnChange(e)}
              accept="image/*"
            >
              {uploadButton}
            </Upload>
          </Form.Item>
        </div>
        <Form.Item
          labelCol={{ span: 24 }}
          label="Recipe Description"
          rules={[
            { required: true },
            { type: "string", min: 30 },
            { whitespace: true, message: "Please input a valid description" },
            {
              validator: (_, value) => {
                if (value && value.replace(/\s+/g, "").length < 30) {
                  return Promise.reject(
                    new Error(
                      "Please enter at least 30 characters excluding whitespaces."
                    )
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          name="desc"
        >
          <Input.TextArea
            placeholder="Recipe Description"
            defaultValue={recipeData.desc === null ? null : recipeData.desc}
          />
        </Form.Item>

        <div className="grid-cols-2 grid gap-4 md:gap-8 lg:gap-12 p-4 md:p-8 lg:p-12 mb-6">
          <Form.Item
            name="category"
            label="Startup Category"
            rules={[{ required: true, message: "Please select a category!" }]}
          >
            <Select
              className="w-full border border-gray-300 focus:border-indigo-500 rounded-md"
              placeholder="Select a category"
              popupClassName="bg-gray-100"
            >
              {GerdCategoryData.map((category) => (
                <Option
                  key={category.value}
                  value={category.value}
                  className="py-2 hover:bg-indigo-100"
                >
                  {category.value}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="tags"
            label="Tags"
            rules={[
              { required: true, message: "Please add at least one tag!" },
            ]}
          >
            <Select
              mode="tags"
              className="w-full"
              placeholder="Add tags"
              popupClassName="bg-gray-100"
              tokenSeparators={[","]}
            >
              {CategoryTagsData.map((tag) => (
                <Option
                  key={tag}
                  value={tag}
                  className="py-2 hover:bg-indigo-100"
                >
                  {tag}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Form.Item wrapperCol={{ offet: 8, span: 16 }} name="submit">
          <Button
            className=" ml-auto"
            type="primary"
            style={{ backgroundColor: "black", color: "white", width: 350 }}
            htmlType="submit"
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
