import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import AddRecipeStep1 from "./AddRecipeStep1";
import AddRecipeStep2 from "./AddRecipeStep2";
import AddRecipeStep3 from "./AddRecipeStep3";
import FullScreenLoadingPage from "../../utils/FullScreenLoadingPage";
import { AddPostFirestore } from "../../Firebase/AddPostFirestore";
import { useAuth } from "../../contexts/AuthContext";
import Navbar from "../../Navbar/Navbar";
import UserSignedIn from "../../Navbar/UserSignedIn";

export default function AddRecipeSteps() {
  const [steps, setSteps] = useState(1);

  const [recipeData, setRecipeData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();
  const developLoading = async () => {
    setIsLoading(true);
    try {
      await AddPostFirestore(recipeData, currentUser);
      setIsLoading(false);
      message.success("Recipe added successfully!");
      setSteps(1);
      setRecipeData({});
    } catch (error) {
      setIsLoading(false);

      setSteps(1);
      setRecipeData({});
    }
  };

  useEffect(() => {

    if (steps === 4) {
      developLoading();
    }
  }, [steps]);
  return isLoading ? (
    <p>
      {" "}
      <FullScreenLoadingPage />
    </p>
  ) : (
    <>
      {!currentUser ? <Navbar /> : <UserSignedIn />}
      <div>
        <div className="flex flex-row items-center justify-center">
          {steps === 1 ? (
            <AddRecipeStep1
              setSteps={setSteps}
              setRecipeData={setRecipeData}
              recipeData={recipeData}
            />
          ) : steps === 2 ? (
            <AddRecipeStep2
              setSteps={setSteps}
              setRecipeData={setRecipeData}
              recipeData={recipeData}
            />
          ) : steps === 3 ? (
            <AddRecipeStep3
              setSteps={setSteps}
              setRecipeData={setRecipeData}
              recipeData={recipeData}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
