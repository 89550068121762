import { ArrowBack } from "@mui/icons-material";
import { Button, Form, Input, message } from "antd";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  updatePassword,
} from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { getAuth } from "firebase/auth";
import { CircularProgress } from "@mui/material";

import { db } from "../Firebase/firebase";
import { doc, increment, updateDoc } from "firebase/firestore";

//Amount of posts a user can create per day
const LIMIT_PER_DAY = 5;

export default function ChangePasswordForm() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const { currentUser, currentUserInformation } = useAuth();
  const [loading, setLoading] = useState(false);

  async function onFinish(values) {
    setLoading(true);
    const { currentPassword } = values;
    if (currentUserInformation?.passwordChangeAttempts <= LIMIT_PER_DAY) {
      // Confirm the current password before showing the change password form
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword
      );

      try {
        await reauthenticateWithCredential(currentUser, credential);

        // Get the Auth instance
        const auth = getAuth();
        updateDoc(doc(db, "users", currentUser.uid), {
          passwordChangeAttempts: increment(1),
        }).then(() => {
          // Send password reset email
          sendPasswordResetEmail(auth, currentUser.email)
            .then(() => {
              message.success(
                "Password reset email sent successfully. Please check your email."
              );
              setLoading(false);
              navigate("/profile/accounts/user_account");
            })
            .catch((error) => {
              message.error(
                "Failed to send password reset email. Please try again. " +
                  error.message
              );
              setLoading(false);
              navigate("/profile/accounts/user_account");
            });
        });

        // setShowChangePasswordForm(true);
      } catch (error) {
        updateDoc(doc(db, "users", currentUser.uid), {
          passwordChangeAttempts: increment(1),
        }).then(() => {
          message.error(
            "Failed to confirm current password. Please try again. " +
              error.message
          );
          setLoading(false);
          navigate("/profile/accounts/user_account");
        });
      }
    } else {
      message.error(
        "You have exceeded the limit of password changes per day. Please try again tomorrow."
      );
      setLoading(false);
      navigate("/profile/accounts/user_account");
    }
  }

  const onChangePasswordFinish = (values) => {
    const { newPassword } = values;

    // Update the password
    updatePassword(currentUser, newPassword)
      .then(() => {
        message.success("Password updated successfully.");
        setShowChangePasswordForm(false);
      })
      .catch((error) => {
        message.error("Failed to update password. Please try again.");
      });
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <ArrowBack
          className="gerdTag-arrowIcon"
          onClick={() => {
            navigate("/profile/accounts/user_account");
          }}
        />
      )}

      {!showChangePasswordForm && (
        <Form form={form} name="confirmPassword" onFinish={onFinish}>
          <Form.Item
            name="currentPassword"
            label="Current password"
            rules={[
              { required: true, message: "Please enter your current password" },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="dashed" htmlType="submit" loading={loading}>
              Confirm
            </Button>
          </Form.Item>
        </Form>
      )}

      {showChangePasswordForm && (
        <Form
          form={form}
          name="changePassword"
          onFinish={onChangePasswordFinish}
        >
          <Form.Item
            name="newPassword"
            label="New password"
            rules={[
              { required: true, message: "Please enter your new password" },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirmNewPassword"
            label="Confirm new password"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your new password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords that you entered do not match")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Change Password
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}
