
const algoliasearch = require("algoliasearch");

// Algolia API keys
const client = algoliasearch("G92CRUKSKL", "1097193e73af4b990cd5d61fcff43551")

// Algolia index

export const algolia_index = client.initIndex("dev_gerdproject");

