import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Dialog_card from "./DialogMenuPages/Dialog_card";
import {
  ArrowRight,
  Twitter,
  Instagram,
  ChevronRight,
  Search,
} from "react-feather";
import { MenuItems } from "./Navbar/MenuItem";
import "./DialogDeclare.css";
import React, { useEffect, useState } from "react";
import { useAuth } from "./contexts/AuthContext";
import { useNavigate } from "react-router-dom/dist";
let RecipeObject = {
  title: "Find A Recipe",
  url: "#",
  cName: "nav-links",
};
let dialogStyles = {
  width: "500px",
  maxWidth: "100%",
  margin: "0 auto",
  position: "fixed",
  left: "50%",
  top: "50%",
  transform: "translate(-50%,-50%)",
  zIndex: "999",
  backgroundColor: "#eee",
  padding: "10px 20px 40px",
  borderRadius: "8px",

  display: "flex",
  flexDirection: "column",
};

let dialogCloseButtonStyles = {
  marginBottom: "15px",
  padding: "3px 8px",
  cursor: "pointer",
  borderRadius: "50%",
  border: "none",
  width: "30px",
  height: "30px",
  fontWeight: "bold",
  alignSelf: "flex-end",
};
function DialogDeclare({ show, ClosePopUp }) {
  const { currentUser, logout } = useAuth();
  const [isFetched, setIsFetched] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser !== undefined) {
      setIsFetched(true);
    }
  }, [currentUser]);

  function handleLoginClick(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/login");
    }
  }

  function handleSignUpClick(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/sign_up");
    }
  }

  async function signOut() {
    logout();
  }

  const [menuObject, setMenuObject] = useState(MenuItems[0]);
  if (!show) {
    return <> </>;
  }

  return (
    <>
      <Dialog open={show} onClose={ClosePopUp} maxWidth="lg">
        {/* Title of the Dialog   */}
        <DialogTitle>
          <div className="container_dialog_title">
            <div>
              {/* {" "}
              <img
                height={150}
                width={150}
                src="https://firebasestorage.googleapis.com/v0/b/gingerrmarket.appspot.com/o/Logo%2FFullLogo_Transparent.png?alt=media&token=a67929d8-8d93-4b95-a9ac-98128632bf21"
              />{" "} */}
              <h3>Gentle-Gourmet</h3>{" "}
            </div>
            <div className="container_header_auth_section">
              <div className="container_header_auth_socialicons">
                <div>
                  {" "}
                  <Twitter />{" "}
                </div>
                <div>
                  {" "}
                  <Instagram />{" "}
                </div>
              </div>
              {
                //If a user is signed in, show the logout button, else show the login and signup buttons.
                currentUser ? (
                  <>
                    <div
                      className="container_header_auth_logout"
                      onClick={() => signOut()}
                    >
                      {" "}
                      Logout{" "}
                    </div>{" "}
                  </>
                ) : (
                  <>
                    <div
                      className="container_header_auth_login"
                      onClick={(e) => handleLoginClick(e)}
                    >
                      {" "}
                      Login{" "}
                    </div>
                    <div
                      className="container_header_auth_signup"
                      onClick={(e) => handleSignUpClick(e)}
                    >
                      {" "}
                      Signup{" "}
                    </div>
                  </>
                )
              }
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="container_cards">
            <div className="top_menu">
              <ul className="pop-up-nav-menu">
                <li>
                  <a
                    className="nav__link"
                    href="#"
                    onClick={() => setMenuObject(RecipeObject)}
                  >
                    Find a Recipe <Search className="search__icon" />
                  </a>
                </li>
                {MenuItems.map((item, index) => {
                  return (
                    <li
                      className="pop_up_nav_menu_items_list"
                      key={index}
                      onClick={() => setMenuObject(item)}
                    >
                      <a className={"nav__link"}>
                        {item.title}
                        
                      </a>
                      <div>
                        <ChevronRight className="arrow__right" />
                        </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <Dialog_card
              show={show}
              defaultPage={menuObject.title}
              recipeSideMenuObject={menuObject}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={ClosePopUp} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogDeclare;
