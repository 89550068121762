import { Container, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import Navbar from "../Navbar/Navbar";
import UserSignedIn from "../Navbar/UserSignedIn";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  subtitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  const {currentUser} = useAuth();

  return (
    <>
    {!currentUser ? <Navbar /> : <UserSignedIn />}
      <Container className={classes.root}>
        <Typography variant="h2" className={classes.title}>
          Privacy Policy
        </Typography>
        <Typography>
          GentleGourmet we operates the gentle-gourmet.com.
        </Typography>
        <Typography>
          This page informs you of our policies regarding the collection, use,
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
        </Typography>
        <Typography>
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy.
        </Typography>

        <Typography variant="h4" className={classes.subtitle}>
          Information Collection and Use
        </Typography>
        <Typography>
          We collect several different types of information for various purposes
          to provide and improve our Service to you. Types of data collected may
          include: Personal Data, Usage Data, and Tracking & Cookies Data.
        </Typography>

        <Typography variant="h4" className={classes.subtitle}>
          Use of Data
        </Typography>
        <Typography>
          GentleGourmet uses the collected data for various purposes, such as:
          providing and maintaining the Service, notifying you about changes to
          our Service, allowing you to participate in interactive features of
          our Service, providing customer care and support, monitoring the usage
          of the Service, and detecting, preventing and addressing technical
          issues.
        </Typography>

        <Typography variant="h4" className={classes.subtitle}>
          Disclosure of Data
        </Typography>
        <Typography>
          Under certain circumstances, GentleGourmet may be required to disclose
          your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </Typography>

        <Typography variant="h4" className={classes.subtitle}>
          Security of Data
        </Typography>
        <Typography>
          The security of your data is important to us, but remember that no
          method of transmission over the Internet, or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </Typography>

        <Typography variant="h4" className={classes.subtitle}>
          Changes to This Privacy Policy
        </Typography>
        <Typography>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on
          this page.
        </Typography>

        <Typography variant="h4" className={classes.subtitle}>
          Contact Us
        </Typography>
        <Typography>
          If you have any questions about this Privacy Policy, please contact
          us:
          <Link to="/pages/contact">Contact Us</Link>
        </Typography>
      </Container>
    </>
  );
};

export default PrivacyPolicy;
