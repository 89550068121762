import { message } from "antd";
import React, { useEffect } from "react";
import PasswordResetConfirmation from "./PasswordResetConfirmationPage";
import EmailVerfifedConfirmationPage from "./EmailVerfifedConfirmationPage";

export default function AuthAction() {

  
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get("mode");
    const oobCode = urlParams.get("oobCode");
    useEffect(() => {
        if (mode === "resetPassword" || mode === "verifyEmail") {
            return;
        } else {
            message.error("Invalid mode.");
        }
    }, [mode, oobCode]);

  // Get url Paramters searching for mode and oobCode
  function resetPassword() {
    return <PasswordResetConfirmation mode={mode} oobCode={oobCode} />;
  }
  function verifyEmail() {
    return <EmailVerfifedConfirmationPage mode={mode} oobCode={oobCode} />;
  }
  // Handle the user management action.
  return (
    <div>
      {(() => {
        switch (mode) {
          case "resetPassword":
            // Display reset password handler and UI.
            return resetPassword();
          case "recoverEmail":
            // Display email recovery handler and UI.
            return <div>recoverEmail</div>;
          case "verifyEmail":
            // Display email verification handler and UI.
            return verifyEmail();
          default:
            // Error: invalid mode.
            return <div>Invalid mode.</div>;
        }
      })()}
    </div>
  );
}
