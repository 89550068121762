import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Button, message } from "antd";
import { deleteDoc, doc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../Firebase/firebase";


export default function ConfirmDeletePost({
  focusedPostToBeDeleted,
  deletePostDialogOpen,
  handleDeletePostDialogClose,
}) {
  const [buttonDeletePostEventState, setButtonDeletePostEventState] =
    useState(false);

  // Function handling the deletion of the post
  const deletePostWithFirebase = () => {
    setButtonDeletePostEventState(true);
    deleteDoc(doc(db, "posts", focusedPostToBeDeleted))
      .then(() => {
        window.location.reload();
        setButtonDeletePostEventState(false);
      })
      .catch((error) => {
        message.error("Unable to delete post. Please try again later.");

        setButtonDeletePostEventState(false);
      });
  };

  return (
    <>
      <Dialog
        open={deletePostDialogOpen}
        onClose={handleDeletePostDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this post?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeletePostDialogClose}
            disabled={buttonDeletePostEventState}
          >
            Close
          </Button>
          <Button
            onClick={() => deletePostWithFirebase()}
            autoFocus
            disabled={buttonDeletePostEventState}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
