import React, { useEffect, useState } from "react";
import Burgermenu from "./Burgermenu";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom/dist";
import { CircularProgress } from "@mui/material";
import DropDownMyAccountMenu from "../Media/DropDownMyAccountMenu";
import "./UserSignedIn.css";
import MobileBurgerMenu from "./MobileBurgerMenu";
function UserSignedIn(props) {
  // Navbar for users that are already logged in
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const { currentUserInformation } = useAuth();
  const navigate = useNavigate();
  return (
    <nav className="NavbarItems-update">
      <div className="menu-section-update">
        <div className="menu-icon-update">
          <Burgermenu />
        </div>
        <div className="links-update">
          {isMobile ? (
            <MobileBurgerMenu />
          ) : (
            <h2 className="logo" onClick={() => navigate("/")}>
              Gentle Gourmet
            </h2>
          )}
        </div>
      </div>
      <div className="right_links-update">
        <div>
          {currentUserInformation !== undefined ? (
            <DropDownMyAccountMenu profile={currentUserInformation} />
          ) : (
            <CircularProgress />
          )}
        </div>
        <div className="patreon-link-update">
          <a href="https://www.patreon.com/GentleGourmet/about">Patreon</a>
        </div>
      </div>
    </nav>
  );
}

export default UserSignedIn;
