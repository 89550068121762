import { Button } from "antd";
import React from "react";
import BookmarkPostTailWind from "../../widgets/BookmarkPostTailWind";

export default function ProfileBookmarksSideBar({ userParams, currentlySignedInUser, userID, posts, currentUser, userInformation }) {
  return (
    <>
      <div className="flex flex-col bg-white px-4 py-4 rounded-lg shadow-lg">
        <div className="flex justify-between mb-2">
          <div className="flex flex-col">
            <h3 className="text-lg font-medium text-black mb-2">Bookmarks</h3>
            <p className="text-md font-medium text-gray-600 mb-4">
              Your Bookmarked recipes are here.
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <BookmarkPostTailWind userParams={userParams} currentlySignedInUser={currentlySignedInUser} userID={userID} currentUser={currentUser} userInformation={userInformation} />
          </div>
        </div>
      </div>
    </>
  );
}
