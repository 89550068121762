import React, { useEffect } from "react";
import Posts from "../Media/Posts";
import Navbar from "../Navbar/Navbar";
import { useAuth } from "../contexts/AuthContext";
import UserSignedIn from "../Navbar/UserSignedIn";
import { ArrowRightAltOutlined, PlusOneOutlined } from "@mui/icons-material";
import "./Home.css";
import PostsShowOnHome from "../../widgets/PostsShowOnHome";
import { Button, Col, Row, message, Input, Form, Checkbox } from "antd";
import ShowPostInfiniteScroll from "../../widgets/ShowPostInfiniteScroll";
import { Alert, Snackbar } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import SiteFooter from "../Footer/SiteFooter";
import { AudioOutlined } from "@ant-design/icons";
import FoodSearch from "../utils/FoodSearch";
import PageStatusHeader from "../HomeHeader/PageStatusHeader";

function Home() {
  // Auth context.
  const { currentUser } = useAuth();
  const [handleSnackbarValue, setHandleSnackbarValue] = React.useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const status = searchParams.get("status");
  // String constants
  const GERD = "GERD-Friendly";
  const IBS = "IBS-Friendly";
  const Gastritis = "Gastritis-Friendly";
  const Barrett = "Barretts-Esophagus-Friendly";
  useEffect(() => {
    if (status === "logout") {
      message.success("logged out successfully");
    }
  }, [status]);
  const handleSnackBarClickEvent = (event, reason) => {
    setHandleSnackbarValue(false);
  };
  return (
    <div>
      {/* {status === "logout" ? message.success("logged out successfully") : null} */}

      {status === "error" ? (
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={handleSnackbarValue}
          autoHideDuration={6000}
          onClose={handleSnackBarClickEvent}
        >
          <Alert
            severity="error"
            onClose={handleSnackBarClickEvent}
            sx={{ width: "100%" }}
          >
            Error Something went wrong when making the changes. Please give it
            another go later.
          </Alert>
        </Snackbar>
      ) : null}

      {status === "success" ? (
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={handleSnackbarValue}
          autoHideDuration={6000}
          onClose={handleSnackBarClickEvent}
        >
          <Alert
            severity="success"
            onClose={handleSnackBarClickEvent}
            sx={{ width: "100%" }}
          >
            Changes to profile have been made and saved!
          </Alert>
        </Snackbar>
      ) : null}
      {!currentUser ? <Navbar /> : <UserSignedIn />}
      {/* <PageStatusHeader /> */}
      <div className="home-container">
       
        <FoodSearch />
        <div className="trending_gerd_posts_container">
          <div
            className="trending_gerd_friendly_post_headline"
            onClick={() => navigate("/explore/GERD-Friendly/all")}
          >
            <div className="trending_gerd_friendly_post_headline_h3">
              <h3>
                Delicious and Digestible: The Hottest Gerd-Friendly Recipes of
                the Moment
              </h3>{" "}
            </div>
            <div className="trending_gerd_friendly_post_headline_icon">
              <ArrowRightAltOutlined fontSize="large" />
            </div>
          </div>
          <div className="trending_posts">
            <PostsShowOnHome amountOfPosts={3} category={GERD} />
          </div>
        </div>
        <div className="trending_gerd_posts_container">
          <div
            className="trending_gerd_friendly_post_headline"
            onClick={() => navigate("/explore/IBS-Friendly/all")}
          >
            <div className="trending_gerd_friendly_post_headline_h3">
              <h3>
                Deliciously Irresistible IBS-Friendly Recipes for a Healthier
                You
              </h3>{" "}
            </div>
            <div className="trending_gerd_friendly_post_headline_icon">
              <ArrowRightAltOutlined fontSize="large" />
            </div>
          </div>
          <div className="trending_posts">
            <PostsShowOnHome amountOfPosts={3} category={IBS} />
          </div>
        </div>
        <div className="trending_gerd_posts_container">
          <div className="trending_gerd_friendly_post_headline">
            <div
              className="trending_gerd_friendly_post_headline_h3"
              onClick={() => navigate("/explore/Gastritis-Friendly/all")}
            >
              <h3>
                Discover Delicious Gastritis-Friendly Recipes for a Healthier
                You
              </h3>{" "}
            </div>
            <div className="trending_gerd_friendly_post_headline_icon">
              <ArrowRightAltOutlined fontSize="large" />
            </div>
          </div>
          <div className="trending_posts">
            <PostsShowOnHome amountOfPosts={3} category={Gastritis} />
          </div>
        </div>
        <div className="trending_gerd_posts_container">
          <div
            className="trending_gerd_friendly_post_headline"
            onClick={() => navigate("/explore/Barretts-Esophagus-Friendly/all")}
          >
            <div className="trending_gerd_friendly_post_headline_h3">
              <h3>
                Elevate Your Cooking Game with Barrett's Esophagus-Friendly
                Recipes
              </h3>{" "}
            </div>
            <div className="trending_gerd_friendly_post_headline_icon">
              <ArrowRightAltOutlined fontSize="large" />
            </div>
          </div>
          <div className="trending_posts">
            <PostsShowOnHome amountOfPosts={3} category={Barrett} />
          </div>
        </div>
        <div className="trending_gerd_posts_container">
          <div className="trending_gerd_friendly_post_headline">
            <div className="trending_gerd_friendly_post_headline_h3">
              <h3>
                Go Global with Gastro-Friendly Recipes: A Tasty Way to Travel
                the World
              </h3>{" "}
            </div>
            <div className="trending_gerd_friendly_post_headline_icon">
              <ArrowRightAltOutlined fontSize="large" />
            </div>
          </div>
          <div className="trending_posts">
            <ShowPostInfiniteScroll />
          </div>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
}

export default Home;
