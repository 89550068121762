import React, { useEffect, useState } from "react";
import dalle from "../images/dalle-e2.jpg"
import { Checkbox, Input } from "antd";
import { useNavigate } from "react-router-dom";
const cateogries = [
    //TODO Update value labels to make sense lmaooo. Maybe for grouping.
    {
      value: "Gastritis-Friendly",
      label: "Gastritis-Friendly",
    },
    {
      value: "IBS-Friendly",
      label: "IBS-Friendly",
    },
    {
      value: "GERD-Friendly",
      label: "GERD-Friendly",
    },
    {
      value: "Barretts-Esophagus-Friendly",
      label: "Barretts-Esophagus-Friendly",
    },
  ];

const FoodSearch = () => {
    const [text, setText] = useState("");
    const [filter, setFilters] = useState([]);
    const navigate = useNavigate();

    const handleCheckboxChange = (value, checked) => {
        if (checked) {
          // Add the filter
          setFilters(prevFilters => [...prevFilters, value]);
        } else {
          // Remove the filter
          setFilters(prevFilters => prevFilters.filter(f => f !== value));
        }
      };
      const searchRecipe = () => {
        const categoryQuery = filter.join(" OR category: ");
    
        navigate(
          `/search?search_query=${text}&calorieTotal=${0} TO ${50000}&category=${categoryQuery}`
        );
        window.location.reload();
      };
  return (
    <div 
  className=" text-white pb-4 mb-4 md:p-8 md:mb-3 m-0 p-0 w-full shadow rounded-lg sm:rounded-lg sm:overflow-hidden sm:mt-4 md:mt-4 lg:mt-4 xl:mt-4 2xl:mt-4 " 
  style={{ backgroundImage: `url(${dalle})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
>
      <div className="flex flex-col  justify-between items-center">
        <h1 className="lg:text-7xl sm:text-4xl text-2xl  font-bold my-4">
          Find the perfect dish for you
        </h1>
        <div className="flex xl:flex-row 2xl:flex-row flex-col lg:flex-row sm:flex-col md:flex-col p-3 content-center justify-between sm:gap-4 gap-4 md:gap-10">
          <div
            className="relative bg-opacity-50 bg-black rounded-lg sm:p-3 md:p-4 p-2 text-white flex flex-col items-center justify-center"
            style={{ backdropFilter: "blur(8px)" }}
          >
            <div className="flex lg:flex-row flex-col md:p-3 items-center  justify-center ">
              <div className="flex flex-col md:mr-4">
                <label
                  htmlFor="foodSearch"
                  className="text-xl font-bold mb-2"
                >
                  Find the tastiest Gastritis-friendly meal:
                </label>
                
                <Input
                onChange={(e) => {
                    
                    setText(e.target.value)}}
                  id="foodSearch"
                  type="text"
                  placeholder="Search Food"
                  className="rounded-lg p-3 "
                />
               <div className="flex mt-2 pt-2">
    <div className="flex items-center mr-4">
        <input id="inline-checkbox" type="checkbox" onChange={(e) => {handleCheckboxChange(e.target.value, e.target.checked)}} value={`${cateogries[0].value}`} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label for="inline-checkbox" className="ml-2 text-sm font-medium text-white dark:text-gray-300">Gastritis-Friendly</label>
    </div>
    <div className="flex items-center mr-4">
        <input id="inline-2-checkbox" type="checkbox" onChange={(e) => {handleCheckboxChange(e.target.value, e.target.checked)}} value={`${cateogries[1].value}`} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label for="inline-2-checkbox" className="ml-2 text-sm font-medium text-white dark:text-gray-300">IBS-Friendly</label>
    </div>
    <div className="flex items-center mr-4">
        <input id="inline-3-checkbox" type="checkbox" onChange={(e) => {handleCheckboxChange(e.target.value, e.target.checked)}} value={`${cateogries[2].value}`} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label for="inline-3-checkbox" className="ml-2 text-sm font-medium text-white dark:text-gray-300">GERD-Friendly</label>
    </div>
    <div className="flex items-center mr-4">
        <input id="inline-4-checkbox" type="checkbox" onChange={(e) => {handleCheckboxChange(e.target.value, e.target.checked)}} value={`${cateogries[3].value}`} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
        <label for="inline-4-checkbox" className="ml-2 text-sm font-medium text-white dark:text-gray-300">Barretts-Esophagus-Friendly</label>
    </div>
</div>
              </div>
              <div>
                <button className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-3 mt-4 px-3 rounded-lg " onClick={() => searchRecipe()}>
                  Find
                </button>
              </div>
            </div>
          </div>
        
          <div
            className="relative bg-opacity-50 bg-black rounded-lg p-0 md:p-6 text-white flex flex-col items-center justify-center mt-4 md:mt-0"
            style={{ backdropFilter: "blur(8px)" }}
          >
          <div className="flex flex-col md:p-3 p-0 content-center items-center">
            <div className="flex flex-col justify-between">
              <label htmlFor="foodSearch" className="text-xl font-bold mb-2">
                Contribute to the community:
              </label>
             
            </div>
            <div>
              <button className="bg-pink-500 hover:bg-pink-600 text-white font-bold py-2 px-5 rounded-lg ml-4" onClick={() => navigate("/new_post")}>
                Upload Dish
              </button>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodSearch;
