import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row, Steps, message } from "antd";
import React, { useEffect, useState } from "react";

export default function AddRecipeStep3({
  setSteps,
  setRecipeData,
  recipeData,
}) {
  const [instructions, setInstructions] = useState([
    {
      id: 0,
      name: "",
    },
    {
      id: 1,
      name: "",
    },
    {
      id: 2,
      name: "",
    },
  ]);
  const [instructionCount, setInstructionCount] = useState(3);
  const [trigger, setTrigger] = useState(false);
  const handlePrevious = () => {
    setSteps(() => 2);
  };

  const addInstructions = () => {
    setInstructions((prev) => [...prev, { id: instructionCount, name: "" }]);
    setInstructionCount(instructionCount + 1);
  };

  const handleRemove = (ingredientIndex) => {
    setInstructions(
      instructions.filter((_, index) => index !== ingredientIndex)
    );
    setInstructionCount(instructionCount - 1);
  };

  const onFinish = (values) => {
    if (instructionCount <= 1) {
      message.error("Failed: Please add at least two instructions");
      return;
    } else {
      // Extracting instructions from values
      const extractedInstructions = instructions.map((instruction, index) => {
        const id = instruction.id;
        return {
          text: values[`instruction-${id}`],
          id: id,
          isTextFilled: true,
          checked: false,
          step: index + 1,
        };
      });
      message.success("Step Added!");
      setRecipeData((prev) => ({
        ...prev,
        instructionsForRecipe: extractedInstructions,
      }));
      setTrigger(true);
    }
  };

  // useEffect hook to watch for changes in recipeData
  useEffect(() => {
    if (recipeData.instructionsForRecipe) {
      setSteps(4);
      setTrigger(false);
    }
  }, [trigger]);

  const onFinishFailed = (errorInfo) => {
    message.error("Failed: " + errorInfo.errorFields[0].errors[0]);
  };

  return (
    <div className="w-full flex flex-col items-center justify-center px-4 md:px-8 lg:px-12 py-6">
      <div className="flex flex-col items-start w-1/2 mb-10 gap-2">
        <h3 className="text-lg md:text-xl lg:text-2xl font-medium text-black mb-2">
          Add New Recipe
        </h3>
        <p className="text-md md:text-lg lg:text-xl font-medium text-gray-600 mb-4">
          Input recipe details
        </p>
      </div>
      <Steps
        current={2}
        className="w-full md:w-3/4 lg:w-1/2"
        items={[
          {
            title: "Recipe Details",
          },
          {
            title: "Ingredients",
          },
          {
            title: "Instructions",
          },
        ]}
      />
      <div className="flex flex-col items-start w-1/2 mb-10 mt-20 gap-2">
        <h3 className="text-lg md:text-xl lg:text-lg font-medium text-black mb-2">
          Ingredients
        </h3>
        <p className="text-md md:text-lg lg:text-xl font-medium text-gray-600 mb-4">
          Enter one ingredients per line
        </p>
      </div>
      {/* Start of the Form */}
      <Form
        className="w-full md:w-3/4 lg:w-1/2 mt-6"
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div className="flex flex-col md:flex-row  justify-between w-full mb-4">
          <div className="flex flex-col items-start w-1/2 mb-10 gap-2 border-r-2 border-solid mr-10">
            <h4 className="text-lg md:text-xl lg:text-lg font-medium text-black mb-2">
              Steps
            </h4>
            <p className="text-md md:text-lg lg:text-xl font-medium text-gray-600 mb-4">
              Enter each steps for making the recipe.
            </p>
            <div className="flex flex-col mb-10 gap-2">
              {instructions.map((instruction, index) => (
                <Form.Item key={instruction.id}>
                  <Row gutter={16}>
                    <Form.Item
                      key={instruction.id}
                      name={`instruction-${instruction.id}`}
                      label={`Instruction ${index + 1}`}
                      rules={[
                        { required: true },
                        { type: "string", min: 5 },
                        { type: "string", max: 1000 },
                        {
                          type: "string",
                          message: "Please enter a valid instruction",
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.replace(/\s+/g, "").length < 5) {
                              return Promise.reject(
                                new Error(
                                  "Please enter at least 5 characters excluding whitespaces."
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input
                        placeholder="Slice the ginger into thin strips..."
                        suffix={
                          <Button
                            type="text"
                            onClick={() => handleRemove(index)}
                            icon={<CloseCircleOutlined />}
                          />
                        }
                      />
                    </Form.Item>
                  </Row>
                </Form.Item>
              ))}
              <div className="mb-10">
                <Button
                  onClick={() => {
                    addInstructions();
                  }}
                >
                  <PlusOutlined /> Add Ingredients
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start w-1/2 mb-10 gap-2">
            <h4 className="text-lg md:text-xl lg:text-lg font-medium text-black mb-2">
              Time
            </h4>
            <p className="text-md md:text-lg lg:text-xl font-medium text-gray-600 mb-4">
              What you'd consider to be the estimated time to cook this meal.
              (in minutes)
            </p>
            <div className="flex flex-col mb-10 gap-2">
              <Form.Item
                name="prepTime"
                label="Prep Time"
                rules={[{ required: true }, { type: "number", min: 1 }]}
                normalize={(value) => (value ? parseInt(value, 10) : "")}
              >
                <Input placeholder="30" type="number" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-around w-1/2 mb-10 gap-10">
          <div className="pr-20">
            <Form.Item wrapperCol={{ offset: 8, span: 16 }} name="prev">
              <Button
                onClick={() => {
                  handlePrevious();
                }}
                className=" mr-4"
                type="primary"
                style={{ backgroundColor: "black", color: "white", width: 250 }}
                htmlType="button"
              >
                Prev
              </Button>
            </Form.Item>
          </div>
          <div className="pl-20">
            <Form.Item wrapperCol={{ offet: 8, span: 16 }} name="submit">
              <Button
                className="ml-4"
                type="primary"
                style={{ backgroundColor: "black", color: "white", width: 250 }}
                htmlType="submit"
              >
                Submit
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
}
