import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import LoginNavBar from "../Navbar/LoginNavBar";
import { useAuth } from "../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, Form, Input, message } from "antd";
import { MailOutlined } from "@mui/icons-material";
import Title from "antd/es/skeleton/Title";
import "./ForgotPassWordConfirmEmail.css";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Gentle-Gourmet
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ForgotPassWordConfirmEmail() {
  const { resetPassword } = useAuth();
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const [form] = Form.useForm();
  const [isResetRequested, setIsResetRequested] = React.useState(false);
  let from = location.state?.from?.pathname || "/login";
  const navigate = useNavigate();

  async function onFinish(values) {
    // Send email with reset password link to the user
    // Here, you would typically make an API request to a server to send the email.
    // For this example, we'll just display a success message.

    try {
      setLoading(true);
      await resetPassword(values.email)
        .then(() => {
          setIsResetRequested(true);
          message.success("We sent a change password request to your email.");
          setLoading(false);
        })
        .catch((ERROR) => {
          setErrorMessage("Failed to reset password");
          setIsResetRequested(false);
          setLoading(false);
          resetForm();
          message.error("Failed to reset password durr" + ERROR);
        });
    } catch (error) {
      setErrorMessage("Failed to reset password");
      setLoading(false);
      message.error("Failed to reset password " + error);
      resetForm();
    }
  }

  const onFinishFailed = (errorInfo) => {
    setLoading(true);
    message.error("Something went wrong please try again. ");
    setLoading(false);
  };

  const resetForm = () => {
    form.resetFields();
    setIsResetRequested(false);
  };

  function navigateToLogin(event) {
    navigate("/login");
  }

  return (
    <>
      <LoginNavBar />
      <div
        className="forgot-password-form-container"
        style={{ maxWidth: 400, margin: "0 auto" }}
      >
        <div className="forgot-password-form">
          {errorMessage && (
            <Alert severity="error" message={errorMessage} closable />
          )}
          {!isResetRequested ? (
            <>
              <h1>Enter Your Email</h1>
              <Form
                name="basic"
                layout="vertical"
                autoComplete="off"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not a valid email address.",
                    },
                    { required: true, message: "Please input your email." },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined style={{ color: "#ccc" }} />}
                    placeholder="Email"
                    size="large"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    loading={loading}
                    disabled={loading}
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </>
          ) : (
            <div className="check-your-email-form-div">
              <h1>Got ya buddy!</h1>
              <h2>We sent a change password request to your email.</h2>
              <p>
                Please check your email for instructions on resetting your
                password.
              </p>
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                onClick={() => navigateToLogin()}
              >
                Back to Login
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot your password?
            </Typography>
            <Typography component="h4" variant="body1">
              Reset password in two quick steps.
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
              />

              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button>
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                onClick={navigateToLogin}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Back
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider> */}
    </>
  );
}
