import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { Button, message } from "antd";
import { doc, increment, updateDoc } from "firebase/firestore";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../Firebase/firebase";

//Amount of changes a user can make per day
const LIMIT_PER_DAY = 5;


export default function PrivateAccountPrivacySafety({
  userAuthProvider,
  currentUser,
  informationChangedSuccess,
  informationChangedError,
}) {
  const navigate = useNavigate();
  const [changePublicProfileValue, setChangePublicProfileValue] =
    React.useState(userAuthProvider?.privateProfile);
  const [allowComments, setAllowComments] = React.useState(
    userAuthProvider?.allowComments
  );
  const { currentUserInformation } = useAuth()
  const [loading, setLoading] = React.useState(false);
  function handlePrivacyAndSafetyChange() {
    setLoading(true);
   
    if (currentUserInformation.dailyAmountOfTimesCanChangeSettings < LIMIT_PER_DAY) {
    updateDoc(doc(db, "users", currentUser.uid), {
      privateProfile: changePublicProfileValue,
      allowComments: allowComments,
      dailyAmountOfTimesCanChangeSettings: increment(1),
    })
      .then(() => {
        setLoading(false);
        navigate(informationChangedSuccess);
        window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        navigate(informationChangedError);
        window.location.reload();
      });
    } else {
      setLoading(false);
      message.error("You have reached your daily limit of changes. Please try again tomorrow. "  + currentUserInformation?.dailyAmountOfTimesCanChangeSettings)
      navigate(informationChangedError);  
    }
  }
  return (
    <div className="edit-account-page-right-side-privacy-and-safety">
      <FormGroup>
        <small>Hide your Profile from the public</small>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => setChangePublicProfileValue(e.target.checked)}
              checked={changePublicProfileValue}
            />
          }
          label="Private Profile"
        />
        <small>
          Allow people to comment on your posts. Disabling this will hide all
          comments, but people will still be able to rate your recipe.
        </small>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => setAllowComments(e.target.checked)}
              checked={allowComments}
            />
          }
          label="Allow Anyone to comment on your recipes"
        />
        <Button
        type="dashed"
          loading={loading}
          variant="contained"
          disabled={loading}
          onClick={() => handlePrivacyAndSafetyChange()}
        >
          Save
        </Button>
      </FormGroup>
    </div>
  );
}
