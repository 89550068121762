import React from 'react'

export const CategoryTagsData = [
        'West African',
		"East Asian",
		"SouthEast Asian",
		"North African",
		"American",
		"East African",
		"Indian",
		"Western European",
		"Eastern European",
		"Creole",
		"North American",
		"South American",
	]
    
 

