import { Divider, List, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

// Settings for the YourAccount click action

export default function UserAccount() {
  const navigate = useNavigate();
  const style = {
    width: "100%",
    maxWidth: 360,
    bgcolor: "background.paper",
  };
  return (
    <div className="edit-account-page-left-side">
      <List sx={style} component="nav" aria-label="mailbox folders">
        <Divider />
        <ListItemButton 
        onClick={() => {
          navigate(`/profile/accounts/user_information`)
        }}>
          <ListItemText primary="Username" />
        </ListItemButton>
        <Divider />
        <ListItemButton
          onClick={() => {
            navigate(`/profile/accounts/change_password`);
          }}
          divider
        >
          <ListItemText primary="Change Password" />
        </ListItemButton>
        <Divider light />
        <ListItemButton
          onClick={() => {
            navigate(`/profile/accounts/gerdtag`);
          }}
          divider
        >
          <ListItemText primary="Change Gerd-Tag" />
        </ListItemButton>
        <Divider light />
      </List>
    </div>
  );
}
