import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import LoginNavBar from "./components/Navbar/LoginNavBar";

import { useAuth } from "./components/contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { Alert, Button, Checkbox, Form, Input, message } from "antd";
import "./SignIn.css";
import { getFunctions, httpsCallable } from "firebase/functions";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleLoginInButton from "./components/Media/GoogleLoginInButton";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Gentle-Gourmet
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const theme = createTheme();
  const { login, currentUser } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [isFetched, setIsFetched] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // Google Recaptcha
  const [recaptcha, setRecaptcha] = React.useState(null);
  const getFunctionsInstance = getFunctions();

  const onRecaptchaChange = (value) => {
    setRecaptcha(value);
  };
  const recaptchaRef = React.useRef(null);
  const [showRedirected, setShowRedirected] = React.useState(false);
  const [showResetPassword, setShowResetPassword] = React.useState(false);
  const verifyRecaptcha = httpsCallable(
    getFunctionsInstance,
    "verifyRecaptcha"
  );

  async function onFinish(values) {
    setLoading(true);
    verifyRecaptcha({ recaptchaToken: recaptcha })
      .then(async () => {
        try {
          await login(values.email, values.password)
            .then(() => {
              setLoading(false);
            })
            .catch((error) => {
              message.error("Failed to login. Please try again.");
              setErrorMessage(error);

              recaptchaRef.current.reset();
              setLoading(false);
            });
        } catch (error) {
          setErrorMessage(error);

          setLoading(false);
          message.error("Failed to login. Please try again.");
          recaptchaRef.current.reset();
        }
      })
      .catch((error) => {
        message.error("Failed to verify recaptcha. Please try again.");
        setErrorMessage("Failed to verify recaptcha. Please try again.");
        recaptchaRef.current.reset();
        setLoading(false);
      });

    //Reset loading state and error message
    setTimeout(() => {
      setLoading(false);
      setErrorMessage("");
    }, 2000);
  }

  const onFinishFailed = (errorInfo) => {
    setLoading(true);
    recaptchaRef.current.reset();

    setLoading(false);
  };

  function handleResetPasswordRequest(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/auth/reset-password-request");
    }
  }

  function handleDontHaveAccountClick(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/sign_up");
    }
  }

  React.useEffect(() => {
    if (currentUser !== undefined) {
      setIsFetched(true);
    }
  }, [currentUser]);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirected = searchParams.get("redirected");
    const resetPassword = searchParams.get("resetPassword");
    if (redirected === "login") {
      setShowRedirected(true);
    }
    if (resetPassword === "true") {
      setShowResetPassword(true);
    }
  }, [location]);
  return (
    <>
      <LoginNavBar />
      {showRedirected && (
        <Alert
          className="redirected-alert"
          message="You have been redirected"
          description="Please login to continue."
          type="info"
          showIcon
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            zIndex: 1000,
          }}
          closable
          onClose={() => setShowRedirected(false)}
        ></Alert>
      )}

      {showResetPassword && (
        <Alert
          className="redirected-alert"
          message="Password Reset"
          description="Please login with your new password."
          type="info"
          showIcon
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            zIndex: 1000,
          }}
          closable
          onClose={() => setShowResetPassword(false)}
        ></Alert>
      )}
      <div className="sign-in-container">
        <div className="sign-in-form">
          {errorMessage && (
            <Alert
              className="redirected-alert"
              message="Login Failed"
              description={errorMessage}
              type="error"
              sx={{ mb: 2 }}
            >
              {errorMessage}
            </Alert>
          )}
          <div className="icon-container">
            <Avatar className="avatar" sx={{ bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
          </div>
          <h1>Welcome Back</h1>

          <Form
            name="basic"
            layout="vertical"
            initalValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  type: "password",
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button
                type="default"
                htmlType="submit"
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                disabled={loading}
                loading={loading}
              >
                Submit
              </Button>
            </Form.Item>
            <div className="recaptcha">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={onRecaptchaChange}
              />
            </div>
            <div className="sign-in-remember-me">
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
            </div>
          </Form>
          <div className="sign-in-footer">
            <GoogleLoginInButton />
            <Link
              href="/auth/reset-password-request"
              onClick={handleResetPasswordRequest}
            >
              Forgot Password?
            </Link>

            <Link href="/sign_up" onClick={handleDontHaveAccountClick}>
              Don't have an account?
            </Link>
          </div>
        </div>
      </div>
      {/* <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
              {!authError ? (
                ""
              ) : (
                <Alert variant="outlined" severity="error">
                  {"Error wrong username or password"}
                </Alert>
              )}
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={emailText}
                onChange={(e) => setEmailText(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={passwordText}
                onChange={(e) => setPasswordText(e.target.value)}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
      {/*
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/reset-password-request" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link onClick={(e) => handleLoginClick(e)} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider> */}
      {Copyright}
    </>
  );
}
