import React, { useEffect } from 'react'
import Review from './Review'
import './Reviews.css'

export default function Reviews({review_profile}) {

  return (
    <div className="reviews_list_posts">
        {review_profile.length > 0 && 
            review_profile.map(review => {
              
                return (
                    <Review key={review.name} reviewData={review}/>
                )
            })}
    </div>
  )
}
