import React, { useEffect, useState } from "react";
import "./Navbar.css";
import Burgermenu from "./Burgermenu";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom/dist";
import "./LoginNavBar.css";

function LoginNavBar(props) {
  // Navbar for login page.
  const [isFetched, setIsFetched] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUser !== undefined) {
      setIsFetched(true);
    }
  }, [currentUser]);

  function handleSignUpClick(e) {
    e.preventDefault();
    if (isFetched) {
      navigate("/sign_up");
    }
  }
  return (
    <nav className="NavbarItemsLogin">
    <div className="menu-section-login">
      <div className="menu-icon-login">
        <Burgermenu />
      </div>
      <div className="links-login">
      <h2 className="logo" onClick={() => navigate("/")}>Gentle Gourmet</h2>
      </div>
    </div>
    <div className="right_links-login">
      <div className="signup-option-login" onClick={(e) => handleSignUpClick(e)}>
        <p>Don't have an account? SignUp</p>
      </div>
    </div>
  </nav>
  );
}

export default LoginNavBar;
