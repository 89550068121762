import { collection, getDocs } from "firebase/firestore";
import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../components/Firebase/firebase";
import { CircularProgress, Rating } from "@mui/material";
import ManipulateDesciption from "../components/utils/ManipulateDesciption";

import ConfirmDeletePost from "../components/utils/ConfirmDeletePost";
import ShowPostSettingButton from "./ShowPostSettingButton";
import { Link, useNavigate } from "react-router-dom";
export default function PostsTailWind({
  title,
  image,
  description,
  rating,
  tags,
  email,
  category,
  postUid,
  currentUserId,
  post,
  userInformation,
  userParams,
  currentlySignedInUser,
}) {
  const [posts, setPosts] = useState([]);
  const [slicedEmail, setSlicedEmail] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getDocs(collection(db, "posts")).then((docSnap) => {
      docSnap.forEach((doc) => {
        setPosts((newposts) => [...newposts, doc]);
        setSlicedEmail((postsEmail) => [...postsEmail, doc.data().email]);
      });
      setLoading(false);
    });
  }, []);

  // New posts component replacing older Posts.js
  return !loading ? (
    <>
      {/* Dialog button to confirm delete post */}
      <ConfirmDeletePost />
      <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
        {image === null ? (
          <img
            className="w-full h-64 object-cover object-center rounded-t-lg"
            src="https://firebasestorage.googleapis.com/v0/b/gingerrmarket.appspot.com/o/defaultImages%2Fphoto-1546069901-ba9599a7e63c.jpg?alt=media&token=c55c094f-91f0-4d7a-85d4-8ae2d125cce2"
            alt="header"
          />
        ) : (
          <img
            className="w-full h-64 object-cover object-center rounded-t-lg cursor-pointer"
            src={image}
            alt="header"
            onClick={() => {
              navigate(`/post/${category}/${postUid}`);
            }}
          />
        )}
        <div className="px-6 py-4">
          {
            <ShowPostSettingButton
              postUid={postUid}
              currentUserId={currentUserId}
              post={post.id}
              userInformation={userInformation}
              userParams={userParams}
              currentlySignedInUser={currentlySignedInUser}
              postUserInformation={post?.data()?.username_of_user}
            />
          }
          <h1 className="text-xl font-bold mb-2 text-gray-800">{title}</h1>

          <p className="text-gray-600 text-base">
            {<ManipulateDesciption desc={description} />}
          </p>
        </div>
        <div className="px-6 py-4">
          <span
            className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-green-400 cursor-pointer"
            onClick={() => navigate(`/explore/${category}/all`)}
          >
            {category}
          </span>
        </div>
        <div className="px-6 py-4">
          <Rating name="read-only" value={rating} readOnly />
        </div>
        <div className="px-6 pt-4 pb-2">
          {tags.map((tag, index) => (
            <span
              onClick={() => navigate(`/explore/${category}/${tag}`)}
              key={index}
              className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-gray-300 cursor-pointer"
            >
              #{tag}
            </span>
          ))}
        </div>
      </div>
    </>
  ) : (
    <CircularProgress />
  );
}
