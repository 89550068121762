import { Pinterest } from '@material-ui/icons';
import React from 'react';

const ShareOnPinterest = ({ url, media, description }) => {
  const shareUrl = `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(media)}&description=${encodeURIComponent(description)}`;
  function shareFunction(shareUrl) {
    window.open(
      shareUrl,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
    );
  }
  return (
    <div
      className="single_post_recipe_utilities_pintrestIcon"
      onClick={() => shareFunction(shareUrl)}
    >
      <Pinterest/>
    </div>
  );
};

export default ShareOnPinterest;