import { Facebook } from '@material-ui/icons';
import React from 'react';

const ShareOnFacebook = ({ url, quote }) => {
  const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(quote)}`;

  function shareFunction(shareUrl) {
    window.open(
      shareUrl,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
    );
  }
  return (
     <div
      className="single_post_recipe_utilities_facebookIcon"
      onClick={() => shareFunction(shareUrl)}
    >
      <Facebook/>
    </div>
  );
};

export default ShareOnFacebook;