export const MenuItems = [
  {
    title: "Explore",
    url: "#",
    cName: "nav-links",
    linkItems: [],
  },
  {
    title: "Gastritis-Friendly",
    url: "",
    cName: "nav-links",
    linkItems: [
      "Breakfast and Brunch",
      "Lunch",
      "Dinner",
      "Evening",
      "Seafood",
      "Dessert",
      "Vegetarian",
      "Smoothie",
      "Soup",
      "East Asian",
      "SouthEast Asian",
      "North African",
      "North American",
      "South American",
      "East African",
      "Indian",
      "West African",
      "Western European",
      "Eastern European",
      "Creole",
    ],
  },
  {
    title: "IBS-Friendly",
    url: "",
    cName: "nav-links",
    linkItems: [
      "Breakfast and Brunch",
      "Lunch",
      "Dinner",
      "Evening",
      "Seafood",
      "Dessert",
      "Vegetarian",
      "Smoothie",
      "Soup",
      "East Asian",
      "SouthEast Asian",
      "North African",
      "North American",
      "South American",
      "East African",
      "Indian",
      "West African",
      "Western European",
      "Eastern European",
      "Creole",
    ],
  },
  {
    title: "GERD-Friendly",
    url: "",
    cName: "nav-links",
    linkItems: [
      "Breakfast and Brunch",
      "Lunch",
      "Dinner",
      "Evening",
      "Seafood",
      "Dessert",
      "Vegetarian",
      "Smoothie",
      "Soup",
      "East Asian",
      "SouthEast Asian",
      "North African",
      "North American",
      "South American",
      "East African",
      "West African",
      "Indian",
      "Western European",
      "Eastern European",
      "Creole",
    ],
  },
  {
    title: "Barretts-Esophagus-Friendly",
    url: "",
    cName: "nav-links",
    linkItems: [
      "Breakfast and Brunch",
      "Lunch",
      "Dinner",
      "Evening",
      "Seafood",
      "Dessert",
      "Vegetarian",
      "Smoothie",
      "Soup",
      "West African",
      "East Asian",
      "SouthEast Asian",
      "North African",
      "North American",
      "South American",
      "East African",
      "West African",
      "Indian",
      "Western European",
      "Eastern European",
      "Creole",
    ],
  },
  {
    title: "Trusted Brands",
    url: "",
    cName: "nav-links",
    linkItems: ["Moderator-Selected"],
  },

];
