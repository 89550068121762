import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  startAt,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../components/Firebase/firebase";
import { Pagination, message } from "antd";
import { CircularProgress, Rating } from "@mui/material";
import ManipulateDesciption from "../components/utils/ManipulateDesciption";

import ShowPostSettingButton from "./ShowPostSettingButton";
import { useNavigate } from "react-router-dom";
export default function BookmarkPostTailWind({
  userID,
  currentUser,
  userInformation,
  currentlySignedInUser,
  userParams
}) {
  const [bookmarkedPosts, setBookmarkedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const postsPerPage = 4; // Number of posts per page
  const [currentPage, setCurrentPage] = useState(1);
  const [firstDocs, setFirstDocs] = useState({ 1: null });
  const [lastDoc, setLastDoc] = useState(null); // Last document of the batch, used for cursor based pagination
  // Get the total amount of bookmarks the user have for the Pagination component to show the correct amount of pages
  const [totalBookmarks, setTotalBookmarks] = useState(null);

  // REFS
  const userRef = doc(db, "users", userID);
  const bookmarksRef = collection(userRef, "bookmarks");

  //Constants
  const navigate = useNavigate();
  useEffect(() => {
    // Get the size of all bookmarks the user has for the Pagination "total" prop
    let totalBookmarksQuery = query(bookmarksRef);

    fetchPosts(1)
      .then(() => {
        getDocs(totalBookmarksQuery)
          .then((snapshot) => {
            setTotalBookmarks(snapshot.size);
            
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => {
        message.error("Something went wrong while fetching posts");
        setLoading(false);
      });
  }, []);

  // Fetch posts for pagination
  const fetchPosts = (page) => {
    return new Promise((resolve, reject) => {
      setLoading(true);
      setCurrentPage(page);

      let bookmarksQuery = query(
        bookmarksRef,
        orderBy("PostID"),
        limit(postsPerPage)
      );

      if (page > 1) {
        if (firstDocs[page]) {
          bookmarksQuery = query(
            bookmarksRef,
            orderBy("PostID"),
            startAt(firstDocs[page]),
            limit(postsPerPage)
          );
        } else {
          bookmarksQuery = query(
            bookmarksRef,
            orderBy("PostID"),
            startAfter(lastDoc),
            limit(postsPerPage)
          );
        }
      }

      getDocs(bookmarksQuery)
        .then(async (bookmarksSnapshot) => {
          if (bookmarksSnapshot.empty) {
            setHasMore(false);
            setLoading(false);
            resolve();
            return;
          }

          if (!firstDocs[page]) {
            setFirstDocs((prev) => ({
              ...prev,
              [page]: bookmarksSnapshot.docs[0],
            }));
          }

          const lastVisible =
            bookmarksSnapshot.docs[bookmarksSnapshot.docs.length - 1];
          setLastDoc(lastVisible);

          let fetchedPosts = [];
          for (const bookmarkDoc of bookmarksSnapshot.docs) {
            const postID = bookmarkDoc?.data().PostID;
            const postRef = doc(db, "posts", postID);
            const postSnapshot = await getDoc(postRef);

            if (
              postSnapshot.exists() &&
              postSnapshot.data().privatePosts === false
            ) {
              fetchedPosts.push(postSnapshot);
            }
          }

          setBookmarkedPosts(fetchedPosts);
          setLoading(false);
          resolve();
        })
        .catch((error) => {
          message.error("Something went wrong while fetching posts", error);
          setLoading(false);
          reject(error);
        });
    });
  };
  return (
    <>
    <div className="flex flex-col items-center justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {loading ? (
          <div className="w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        ) : Array.isArray(bookmarkedPosts) && bookmarkedPosts?.length > 0 ? (
          <>
            {bookmarkedPosts.map((post, index) => (
              <div
                key={post?.data().title}
                className="max-w-lg mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
              >
                {post?.data().imageUrl === null ? (
                  <img
                    className="w-full h-64 object-cover object-center rounded-t-lg cursor-pointer"
                    src="https://firebasestorage.googleapis.com/v0/b/gingerrmarket.appspot.com/o/defaultImages%2Fphoto-1546069901-ba9599a7e63c.jpg?alt=media&token=c55c094f-91f0-4d7a-85d4-8ae2d125cce2"
                    alt="header"
                  />
                ) : (
                  <img
                    className="w-full h-64 object-cover object-center rounded-t-lg cursor-pointer"
                    src={post?.data().imageUrl}
                    onClick={() => navigate(`/post/${post?.data().category}/${post?.id}`)}
                    alt="header"
                  />
                )}
                <div className="px-6 py-4">
                  <ShowPostSettingButton
                  postUserInformation={post?.data()?.username_of_user}
                    postUid={post.id}
                    post={post}
                    currentUserId={currentUser}
                    userInformation={userInformation}
                    currentlySignedInUser={currentlySignedInUser}
                    userParams={userParams}
                    
                  />

                  <h1 className="text-xl font-bold mb-2 text-gray-800">
                    {post?.data().title}
                  </h1>
                  <p className="text-gray-600 text-base">
                    {<ManipulateDesciption desc={post?.data().desc} />}
                  </p>
                </div>
                <div className="px-6 py-4">
                  <span    onClick={() => navigate(`/explore/${post?.data().category}/all`)} className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-green-400 cursor-pointer">
                    {post?.data().category}
                  </span>
                </div>

                <div className="px-6 py-4">
                  <Rating
                    name="read-only"
                    value={post?.data().rating}
                    readOnly
                  />
                </div>
                <div className="px-6 pt-4 pb-2">
                  {Array.isArray(post?.data().tags) &&
                    post?.data().tags.map((tag, index) => (
                      <span
                        key={index}
                        onClick={() => navigate(`/explore/${post?.data().category}/${tag}`)}
                        className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-gray-300 cursor-pointer"
                      >
                        #{tag}
                      </span>
                    ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="w-full flex justify-center items-center">
            <h1 className="text-xl font-bold mb-2 text-gray-800">
              You have no bookmarked posts
            </h1>
          </div>
        )}
  
      </div>
      <div className="w-full flex justify-center mt-5">
          {totalBookmarks === null ? (
            <CircularProgress />
          ) : (
            <Pagination
              current={currentPage}
              onChange={fetchPosts}
              pageSize={postsPerPage}
              total={totalBookmarks === null ? 0 : totalBookmarks}
            />
          )}
        </div>
        </div>
    </>
  );
}
