import { ArrowBack } from "@mui/icons-material";
import { ListItemButton, ListItemText } from "@mui/material";
import { Button, Divider, Form, Input, List, message } from "antd";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
} from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export default function ShowUserInformation({ userData, style }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const { currentUser } = useAuth();
  const [authenticateLoading, setAuthenicateLoading] = useState(false);

  async function authenticateAccountWithGoogle() {
    setAuthenicateLoading(true);
    try {
      await reauthenticateWithPopup(currentUser, new GoogleAuthProvider());
      setShowChangePasswordForm(true);
    } catch (error) {
      message.error("Failed to authenticate with Google. Please try again.");
    } finally {
      setAuthenicateLoading(false);
    }
  }
  async function onFinish(values) {
    const { currentPassword } = values;
    // Confirm the current password before showing the change password form
    const credential = EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );

    try {
      const result = await reauthenticateWithCredential(
        currentUser,
        credential
      );
      setShowChangePasswordForm(true);
    } catch (error) {
      message.error("Failed to confirm current password. Please try again.");
    }

  }
  return (
    <div>
      <ArrowBack
        className="userinformation-arrowIcon"
        onClick={() => {
          navigate(`/profile/accounts/user_account`);
        }}
      />
      {!showChangePasswordForm && (
        <>
          <Form form={form} name="confirmPassword" onFinish={onFinish}>
            <Form.Item
              name="currentPassword"
              label="Enter your Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="dashed" htmlType="submit">
                Confirm
              </Button>
            </Form.Item>
          </Form>
          <Button onClick={() => authenticateAccountWithGoogle()}>
            Authenticate with Google
          </Button>
        </>
      )}

      {showChangePasswordForm && (
        <div>
          <List sx={style} component="nav" aria-label="mailbox folders">
            <Divider />
            <h3>Username</h3>
            <Divider />
            <ListItemText primary={`${userData.username}`} />
            <Divider />
            <h3>Email</h3>
            <Divider />
            <ListItemText primary={`${userData.email}`} />
            <Divider />
          </List>
        </div>
      )}
    </div>
  );
}
