import React, { useState } from "react";
import "../SearchBar/RecipeSearch.css";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { algolia_index } from "../Algolia/algolia_posts";
import { Checkbox, Form, Input, message, Slider } from "antd";

function RecipeSearch(props) {
  const navigate = useNavigate();
  const [recipeName, setRecipeName] = useState("");
  const marks = {
    0: "0",
    250: "250",
    500: "500",
    1000: "1000",
    1500: "1500",
    2000: "2000",
    2500: "2500",
    3000: "3000",
    3500: "3500",
    4000: {
      style: {
        color: "#f50",
      },
      label: <strong>4000</strong>,
    },
  };
  const cateogries = [
    //TODO Update value labels to make sense lmaooo. Maybe for grouping.
    {
      value: "Gastritis-Friendly",
      label: "Gastritis-Friendly",
    },
    {
      value: "IBS-Friendly",
      label: "IBS-Friendly",
    },
    {
      value: "GERD-Friendly",
      label: "GERD-Friendly",
    },
    {
      value: "Barretts-Esophagus-Friendly",
      label: "Barretts-Esophagus-Friendly",
    },
  ];
  function searchAction() {
    const search_record = { title: recipeName };
  }

  //Handle on finish event.
  const searchRecipe = (values) => {
    const categoryQuery = values.category.join(" OR category: ");

    navigate(
      `/search?search_query=${values.recipe_name}&calorieTotal=${values.calories[0]} TO ${values.calories[1]}&category=${categoryQuery}`
    );
    window.location.reload();
  };

  const searchRecipeFailed = (errorInfo) => {
    message.error("Failed:", errorInfo);
  };
  return (
    <>
      <div className="searchBlock_content">
        <h2>What can we help you find?</h2>
        <Form
          onFinish={searchRecipe}
          onFinishFailed={searchRecipeFailed}
          autoComplete="off"
          className="searchBlock_form"
        >
          <Form.Item className="keyword_box" name="recipe_name">
            <Input
              placeholder="Enter Recipe Name or Keyword"
              onChange={(e) => setRecipeName(e.target.value)}
            />
          </Form.Item>
          <Form.Item name="category">
            <Checkbox.Group options={cateogries}>Filter Results</Checkbox.Group>
          </Form.Item>
          <strong>Calories</strong>
          <Form.Item name="calories">
            <Slider
              range={{ draggableTrack: true }}
              min={0}
              max={4000}
              initialValues={[30, 60]}
              tooltip={{ open: true }}
              marks={marks}
            />
          </Form.Item>
          <div className="searchBlock_form__button">
            <Form.Item>
              <Button className="btn--primary">Submit</Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
}

export default RecipeSearch;
