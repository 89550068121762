import React from "react";
import { Container, Typography, Box, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../contexts/AuthContext";
import Navbar from "../Navbar/Navbar";
import UserSignedIn from "../Navbar/UserSignedIn";
import { Divider } from "antd";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      padding: theme.spacing(3),
      backgroundColor: '#f7f7f7',
    },
    title: {
      paddingBottom: theme.spacing(2),
      color: '#3f51b5',
    },
    subtitle: {
      paddingBottom: theme.spacing(4),
      color: '#757575',
    },
    sectionTitle: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      color: '#3f51b5',
    },
    paper: {
      padding: theme.spacing(4),
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
      borderRadius: '8px',
    },
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  }));

const OurVision = () => {
  const classes = useStyles();
  const {currentUser} = useAuth();
  return (
    <>
    {!currentUser ? <Navbar /> : <UserSignedIn />}
    <Container className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        Our Vision
      </Typography>
      <Typography variant="h5" className={classes.subtitle} gutterBottom>
        Empowering Gastritis Sufferers Through Shared Recipes and Knowledge
      </Typography>
      <Paper className={classes.paper}>
        <Typography>
          At Gentle Gourmet, we believe that everyone deserves access to delicious, wholesome, and healing meals. Our vision is to create a global community where people with Gastritis conditions can easily discover, share, and enjoy recipes tailored to their specific needs.
        </Typography>
        <Divider className={classes.divider} />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className={classes.sectionTitle}>
              A Supportive Community
            </Typography>
            <Typography>
              - Create a safe, supportive, and welcoming space for people with Gastritis conditions.
            </Typography>
            <Typography>
              - Foster connections between users, allowing them to share their experiences, tips, and favorite recipes.
            </Typography>
            <Typography>
              - Encourage collaboration and open communication, empowering users to learn from one another and grow together.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className={classes.sectionTitle}>
              A Comprehensive Resource
            </Typography>
            <Typography>
              - Offer a vast, easily searchable database of Gastritis-friendly recipes, curated and contributed by users.
            </Typography>
            <Typography>
              - Include detailed information on ingredients, preparation, and nutritional content to help users make informed choices.
            </Typography>
            <Typography>
              - Continually update and expand our database with new, innovative recipes that cater to various tastes and dietary needs.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" className={classes.sectionTitle}>
              Accessibility and Inclusivity
            </Typography>
            <Typography>
              - Design a user-friendly, accessible platform that caters to a diverse range of users, regardless of their technical skills or dietary preferences.
            </Typography>
            <Typography>
            - Offer multi-language support, ensuring our community is inclusive and welcoming to users from around the world.
            </Typography>
        <Typography>
          - Implement responsive design for optimal viewing and interaction on various devices, including desktops, tablets, and smartphones.
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h4" className={classes.sectionTitle}>
          Education and Awareness
        </Typography>
        <Typography>
          - Provide informative articles, resources, and tools to help users understand the causes, symptoms, and management of Gastritis conditions.
        </Typography>
        <Typography>
          - Partner with medical professionals, dietitians, and other experts to share the latest research and best practices in Gastritis care.
        </Typography>
        <Typography>
          - Raise awareness about Gastritis conditions and promote a healthy, balanced lifestyle for optimal well-being.
        </Typography>
      </Grid>
    </Grid>
    <Divider className={classes.divider} />
    <Box mt={2}>
      <Typography variant="h5">
        By uniting people with Gastritis conditions through shared recipes and knowledge, we aim to improve the quality of life for our users and empower them to take control of their health. Together, we can build a brighter, healthier future for everyone.
      </Typography>
    </Box>
  </Paper>
</Container>
           
  </>);
};

export default OurVision;
