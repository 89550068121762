import { Link } from "react-router-dom";
import { Layout } from "antd";
import "./Footer.css";

const { Footer } = Layout;

const SiteFooter = () => {
  return (
    <Footer className="footer">
    <div className="footer-top">
      <div className="footer-logo">
        <h2>Gentle-Gourmet</h2>
      </div>
      <div className="footer-links">
        <div className="footer-column">
          <h3>Links</h3>
          <Link to="/pages/contact">Contact</Link>
          <Link to="/pages/our-vision">Our Vision</Link>
          <Link to="https://www.patreon.com/GentleGourmet/about">Patreon</Link>
        </div>
        <div className="footer-column">
          <h3>Legal</h3>
          <Link to="/pages/privacy-policy">Privacy</Link>
          {/* <Link to="/pages/terms-and-conditions">Terms and Conditions</Link>
          <Link to="/pages/notice-for-california-residents">
            Notice for California Residents
          </Link> */}
        </div>
      </div>
    </div>
    <div className="footer-bottom">
      <p>© 2023 Gentle-Gourmet. All Rights Reserved.</p>
    </div>
  </Footer>
  );
};

export default SiteFooter;