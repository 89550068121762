import React, { useState, useEffect } from "react";
import { Form, Input, Button, Typography, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import "./PasswordResetConfirmationPage.css";
import {
  confirmPasswordReset,
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  updateCurrentUser,
  verifyPasswordResetCode,
} from "firebase/auth";
import { auth } from "../Firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";

const { Title } = Typography;

const PasswordResetConfirmation = ({ mode, oobCode }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const functions = getFunctions();
  const auth = getAuth();

  const onFinish = async (values) => {
    if (mode !== "resetPassword" || !oobCode) {
      message.error("Invalid password reset link.");
      return;
    }

    setLoading(true);

    verifyPasswordResetCode(auth, oobCode)
      .then((email) => {
        confirmPasswordReset(auth, oobCode, values.password)
          .then(async () => {
            const enableUserAndResetPassword = httpsCallable(
              functions,
              "enableUserAndResetPassword"
            );
            await enableUserAndResetPassword({
              email,
              password: values.password,
            });
            message.success("Your password has been reset successfully!");
            navigate("/login?resetPassword=true");
          })
          .catch((error) => {
            message.error("Failed to reset password. " + error.message);
            setLoading(false);
          });
      })
      .catch((error) => {
        message.error("Failed to reset password. " + error.message);
        setLoading(false);
      });
  };

  const onFinishFailedFeedBack = async (errorInfo) => {
    setLoading(true);
    message.error("Failed to reset password. ", errorInfo);
    setLoading(false);
  };

  return (
    <div className="password-reset-confirmation-container">
      <div className="password-reset-confirmation-form">
        <Title level={2}>Reset Password</Title>
        <Form onFinish={onFinish} onFinishFailed={onFinishFailedFeedBack}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm New Password"
            />
          </Form.Item>

          <Form.Item>
            <Button
              disabled={loading}
              type="primary"
              htmlType="submit"
              loading={loading}
              block
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PasswordResetConfirmation;
