
import React, {useState} from 'react';
import {AlignJustify, X} from 'react-feather';
import DialogDeclare from '../DialogDeclare'
function Burgermenu() {
    //Burger menu that shows up on the side of the navbar page.
    function handleClick(){
     setActive(!active)
    }
    function CloseDialogue(){
     setActive(!active)
    }
    const [active, setActive] = useState(false)
    
    return (<><div onClick={handleClick}>{active ? <X /> : <AlignJustify />}</div>
    <DialogDeclare  show={active} ClosePopUp = {CloseDialogue} /></>
    );
}


export default Burgermenu;