import React from "react";

export default function FullScreenLoadingPage() {
  return (
    <div className="flex justify-center items-center w-full h-screen">
    <div className="flex flex-col justify-center items-center w-full">
      <div className="items-center">
        <h1> Adding Your Recipe... </h1>
      </div>
      <div className="flex justify-center items-center w-full">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    </div>
  </div>
  );
}
