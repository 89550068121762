import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Image,
  message,
  Modal,
  Result,
  Row,
  Steps,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { deleteDoc, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../Firebase/firebase";
import { useAuth } from "../contexts/AuthContext";
import "./DeactivateAccountPage.css";
import UserSignedIn from "../Navbar/UserSignedIn";

const { Step } = Steps;
const { Title, Text } = Typography;
const DeactivateAccount = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentUser, logErrorToDatabase, logout } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  serverTimestamp();
  const showModal = () => {
    setVisible(true);
  };

  const hideModal = () => {
    setVisible(false);
    setCurrentStep(0);
  };

  const handleDeleteAccount = () => {
    setLoading(true);
    updateDoc(doc(db, "users", currentUser.uid), {
      markedForDeletion: true,
      dateMarkedForDeletion: serverTimestamp(),
      privateProfile: true,
      newsletter: false,
    })
      .then(() => {
        hideModal();
        logout();
        navigate("", { replace: true });
      })
      .catch((err) => {
        setLoading(false);
        message.error("Error deleting account");
        logErrorToDatabase(
          err,
          "Error deleting account " + currentUser.uid + " account"
        );
      })

      .catch((error) => {
        setLoading(false);
        logErrorToDatabase(
          error,
          "Error deleting account " + currentUser.uid + " account"
        );

      });
  };

  const steps = [
    {
      title: "Step 1",
      content:
        "Deleting your account does not mean the end! You will still be able to log in. However, please note that your account will be permanently deleted after a 30-day period. During this period, your posts will be set to private, and you will be removed from the newsletter. If you change your mind and want to keep your account, you can reverse the process by logging back in before the thirty day period ends. ",
    },
    {
      title: "Step 2",
      content: "Are you sure you wish to deactivate?",
    },
  ];

  return (
    <>
      <UserSignedIn />
      <div className="deactivate-account-container">
        <Result
          status="error"
          title="Deactivate Account"
          subTitle="Are you sure you want to deactivate your account? Your account will be deleted permanently."
          extra={[
            <Button
              loading={loading}
              type="primary"
              key="console"
              onClick={showModal}
            >
              Deactivate Account
            </Button>,
          ]}
        />

        <Modal
          open={visible}
          onCancel={hideModal}
          footer={
            currentStep === 0
              ? [
                  <Button
                    key="cancel"
                    className="cancel-btn"
                    onClick={hideModal}
                  >
                    Cancel
                  </Button>,
                  <Button
                  
                    key="next"
                    className="next-btn"
                    onClick={() => setCurrentStep(1)}
                  >
                    Next
                  </Button>,
                ]
              : [
                  <Button
                  loading={loading}
                    key="back"
                    className="back-btn"
                    onClick={() => setCurrentStep(0)}
                  >
                    Back
                  </Button>,
                  <Button
                  loading={loading}
                    key="delete"
                    className="delete-btn"
                    onClick={handleDeleteAccount}
                  >
                    Delete Account
                  </Button>,
                ]
          }
        >
          <Steps current={currentStep} className="steps-container">
            {steps.map((step) => (
              <Step key={step.title} title={step.title} />
            ))}
          </Steps>
          <div className="steps-content">{steps[currentStep].content}</div>
        </Modal>
      </div>
    </>
  );
};

export default DeactivateAccount;
